import { FunctionComponent, useEffect, useState } from "react";
import { capitalize } from "../../pages/recruiter/CandidateDetails/Details";
import { ReactComponent as Expand } from "../../images/icons/expand.svg";
import { ReactComponent as Minimize } from "../../images/icons/minimize.svg";
import { DepartmentType } from "../employee/leads/registerNewLead/RegisterNewLead";
import { Button } from "../common";
import { EditForm } from "./EditForm";
import { deleteDepartment } from "../../api/department";
import { deleteOffice } from "../../api/office";
import { components as APIcomponents } from "../../api/schema";
import { deleteTeam } from "../../api/teams";

type OfficeType = APIcomponents["schemas"]["Office"];
type TeamsType = APIcomponents["schemas"]["Teams"];

interface StructureListProps {
  structure: OfficeType[] | DepartmentType[] | TeamsType[];
  title: string;
  type: "office" | "department" | "team";
}

const StructureList: FunctionComponent<StructureListProps> = ({
  structure,
  title,
  type,
}) => {
  const [showList, setShowList] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [list, setList] = useState<
    OfficeType[] | DepartmentType[] | TeamsType[]
  >([]);
  const [itemToEdit, setItemToEdit] = useState();
  const handleClick = () => {
    setShowList(!showList);
  };

  useEffect(() => {
    setList(structure);
  }, [structure]);

  const deleteItem = async (
    item: OfficeType | DepartmentType,
    type: "office" | "department" | "team"
  ) => {
    if (item._id) {
      if (type === "department") {
        await deleteDepartment(item._id);
        const newList = structure.filter(
          (department) => department._id !== item._id
        );
        setList(newList);
      }
      if (type === "office") {
        await deleteOffice(item._id);
        const newList = structure.filter((office) => office._id !== item._id);
        setList(newList);
      }
      if (type === "team") {
        await deleteTeam(item._id);
        const newList = structure.filter((team) => team._id !== item._id);
        setList(newList);
      }
    }
  };

  const prepareToEdit = (item) => {
    setItemToEdit(item);
    setEditItem(true);
  };

  return (
    <div>
      <div className="flex row-span-2 mt-14 place-content-between ">
        <h4 className="text-h4">List all {title}</h4>
        <button onClick={handleClick}>
          {showList ? (
            <Expand className="self-center" />
          ) : (
            <Minimize className="self-center" />
          )}
        </button>
      </div>
      {showList && (
        <table className="w-full max-w-5xl my-4">
          <thead className="w-full text-gray-500 font-medium">
            <tr className="border-b-1">
              <td>{capitalize(title)}</td>
              <td>Delete</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {list.map((item: OfficeType | DepartmentType) => {
              return item ? (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>
                    <Button
                      type="button"
                      color="lobster"
                      onClick={() => deleteItem(item, type)}
                      name="Delete"
                    ></Button>
                  </td>
                  <td>
                    <Button
                      type="button"
                      color="sea"
                      onClick={() => prepareToEdit(item)}
                      name="Edit"
                    ></Button>
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      )}
      {editItem && itemToEdit && <EditForm type={type} item={itemToEdit} />}
    </div>
  );
};

export default StructureList;
