import { FC, useMemo, useContext } from "react";
import { useTable, useSortBy } from "react-table";

import { Reward } from "../../../components/reward/Reward";
import { components } from "../../../api/schema";
import classNames from "classnames";
import { getTeamAvatar } from "../../../helpers/teams";
import { iteraStructureContext } from "../../../helpers/useIteraStructureContext";

type PointsItem = components["schemas"]["PointsItem"];

interface Props {
  data: PointsItem[];
}

export const TeamLeaderboard: FC<Props> = ({ data }) => {
  const { getTeams } = useContext(iteraStructureContext);

  const cashedData = useMemo(
    () => data.sort((a, b) => b.points - a.points),
    [data]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Row",
        accessor: "row",
        Cell: ({ cell: { row } }) => (
          <div className="text-left mr-4">{row.index + 1}.</div>
        ),
      },
      {
        Header: "Team avatar",
        accessor: "avatar",
        Cell: ({ cell: { row } }) => (
          <div className="relative w-14 mr-4">
            <div className="absolute -translate-y-1/2">
              <Reward
                name={row.original.name}
                teamScore={row.original.points}
                avatarUrl={getTeamAvatar(row.original.name)}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "Team",
        accessor: "name",
        Cell: ({ cell: { value, row } }) => (
          <div
            className={classNames(
              "p-4 border border-black mr-4",
              row.original.is_mine ? "bg-lavenderBright" : ""
            )}
          >
            {getTeams(value)?.name}
          </div>
        ),
      },
      {
        Header: "Points",
        accessor: "points",
        Cell: ({ cell: { value, row } }) => (
          <div
            className={classNames(
              "p-4 border border-black",
              row.original.is_mine ? "bg-lavenderBright" : ""
            )}
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: cashedData }, useSortBy);
  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()} className="w-full overflow-x-scroll">
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${i === 2 ? "w-full" : ""} h-14  py-6`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
