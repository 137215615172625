import { FC } from "react";
import { PageLayout } from "../../components/layout/Layout";
import { ReactComponent as Explanation } from "../../images/HowItWorks.svg";
import { Link, useParams } from "react-router-dom";
import { Path } from "../../routes";
interface ToggleButtonProps {
  text: string;
  selected: boolean;
  path: string;
}

const ToggleButton: FC<ToggleButtonProps> = ({ text, selected, path }) => {
  return (
    <Link
      to={path}
      className={
        "flex items-center justify-center w-2/6 h-[98px] text-fuchsia text-h4 " +
        (selected
          ? "bg-lavenderBright shadow-[inset_0px_4px_4px_rgba(0,0,0,0.25)] "
          : "bg-indigo-500/50 border-3 border-lavenderBright shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ")
      }
    >
      {text}
    </Link>
  );
};

export const HowItWorks: FC = () => {
  const params = useParams();
  return (
    <>
      <div className="flex flex-row justify-center pt-12">
        <ToggleButton
          path={Path.WHATHAPPENS}
          selected={params.topic == "what-happens" || params.topic == ":topic"}
          text="What happens when I submit a Lead?"
        />
        <ToggleButton
          path={Path.POINTSYSTEM}
          selected={params.topic == "point-system"}
          text="The point system"
        />
      </div>
      {params.topic == "what-happens" || params.topic == ":topic" ? (
        <PageLayout>
          <div className="mx-auto max-w-5xl w-full">
            <Explanation />
          </div>
        </PageLayout>
      ) : (
        <PageLayout>
          <div className="mx-auto w-3/5 max-w-5xl">
            <h4 className="text-h4">Individual level</h4>
            <p className="my-4">
              You get points based on your leads. For submitting a lead and the
              progress the candidate makes in the recruitment process you get
              more and more points. The person with the most points after 6
              months wins a dinner with family/friends. Second and Third place
              also get a small token of appreciation. If any of the leads lead
              to a hiring, you get the bonus money in addition. *
            </p>
            <h4 className="text-h4 ">Team level</h4>
            <p className="my-4">
              The teams are the same as you usually are in Itera. All the people
              in the team gather points individually and the points are then
              calculated for the team as a whole. Every 6 months the team with
              the most points get a prize to do something fun together.
            </p>

            <h4 className="text-h4">The points</h4>
            <p className="my-4">
              Based on how far your lead is in the process, you will be awarded
              points
            </p>
            <table className="my-4 w-1/2">
              <tbody>
                <tr className="border-b-1">
                  <td>Status / Progress of the lead</td>
                  <td>Points</td>
                </tr>
                <tr>
                  <td>New</td>
                  <td>500</td>
                </tr>
                <tr>
                  <td>Accepted</td>
                  <td>1500</td>
                </tr>
                <tr>
                  <td>First Interview</td>
                  <td>5000</td>
                </tr>
                <tr>
                  <td>Second Interview</td>
                  <td>10000</td>
                </tr>
                <tr>
                  <td>Third Interview</td>
                  <td>13000</td>
                </tr>
                <tr>
                  <td>Offer</td>
                  <td>20000</td>
                </tr>
                <tr>
                  <td>Hired</td>
                  <td>50000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </PageLayout>
      )}
    </>
  );
};
