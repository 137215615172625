import { FC, useEffect, useMemo, useState, useRef } from "react";
import { useTable, useSortBy, useFilters } from "react-table";

import { Spinner } from "../../components/common/Spinner";
import { Switch } from "../../components/common/Switch";
import { useApi } from "../../helpers/useApi";
import { getToken } from "../../helpers/useTestRecruiter";
import { Button } from "../../components/common";
import { SearchByFilter } from "../../components/common/SearchByFilter/SearchByFilter";
import { ReactComponent as Expand } from "../../images/icons/expand.svg";
import { ReactComponent as Minimize } from "../../images/icons/minimize.svg";
import { headerStyle } from "../recruiter/helpers";
import { UserType } from "../../components/employee/dashboard/stats/PointsOverview";

enum Role {
  EMPLOYEE = "employee",
  RECRUITER = "recruiter",
  ADMIN = "admin",
}

const messages = {
  saving: "Saving in process",
  noChanges: "Nothing changed",
  makingChanges: "You are making changes",
  savedWithSuccess: "Your changes have been successfully saved",
  savedWithErrors: "An error occurred while saving",
};

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5555/api/v1";

const updateRoles = async (userId: string, roles: Role[]) =>
  fetch(`${apiUrl}/users/${userId}/roles`, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ roles }),
  });

interface User {
  id: string;
  name: string;
  email: string;
  hasRecruiterRole: boolean | undefined;
}

interface UserRoles {
  [id: string]: Role[];
}

export const AccessControl: FC = () => {
  const changedRoles = useRef<UserRoles>({});
  const { data, isLoading } = useApi<UserType[]>("/users");
  const [users, setUsers] = useState<User[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [message, setMessage] = useState(messages.noChanges);

  useEffect(
    () =>
      data &&
      setUsers(
        data.map(({ external_id, name, email, roles }) => ({
          id: external_id,
          name,
          email,
          hasRecruiterRole: roles?.includes(Role.RECRUITER),
        }))
      ),
    [data]
  );

  const getChangeRecruiterRole =
    ({ id, hasRecruiterRole }) =>
    (switchOn: boolean) => {
      const isRoleChanged =
        (hasRecruiterRole && !switchOn) || (!hasRecruiterRole && switchOn);
      if (isRoleChanged) {
        changedRoles.current[id] = switchOn ? [Role.RECRUITER] : [];
        setMessage(messages.makingChanges);
        setIsChanged(true);
      } else {
        delete changedRoles.current[id];
        const changesCount = Object.keys(changedRoles.current).length;
        if (!changesCount) {
          setMessage(messages.noChanges);
          setIsChanged(false);
        }
      }
    };

  const saveAccessChanges = async () => {
    const savedRoles = {};
    const unsavedRoles = {};
    setIsSaving(true);
    try {
      const changedRolesWithUserId = Object.entries(changedRoles.current);
      const responds = await Promise.all(
        changedRolesWithUserId.map(([id, roles]) => updateRoles(id, roles))
      );
      responds.forEach(({ ok }, i) => {
        const [id, roles] = changedRolesWithUserId[i];
        (ok ? savedRoles : unsavedRoles)[id] = roles;
      });
      changedRoles.current = {};
      const unsavedNames = users
        .filter(({ id }) => unsavedRoles[id])
        .map(({ name }) => name)
        .join(", ");
      if (unsavedNames) {
        setMessage(`${messages.savedWithErrors} roles for ${unsavedNames}`);
      } else {
        setMessage(messages.savedWithSuccess);
      }
    } catch (error) {
      setMessage(`${messages.savedWithErrors}: ${JSON.stringify(error)}`);
    }
    setUsers(
      users.map((user) =>
        savedRoles[user.id]
          ? {
              ...user,
              hasRecruiterRole: savedRoles[user.id].includes(Role.RECRUITER),
            }
          : user
      )
    );
    setIsChanged(false);
    setIsSaving(false);
  };

  const sortTypes = useMemo(
    () => ({
      text: (rowA, rowB, id: string) =>
        (rowA.values[id].toLowerCase() > rowB.values[id].toLowerCase() && 1) ||
        (rowB.values[id].toLowerCase() > rowA.values[id].toLowerCase() && -1) ||
        0,
      boolean: (rowA, rowB, id) =>
        (!rowA.values[id] && rowB.values[id] && 1) ||
        (rowA.values[id] && !rowB.values[id] && -1) ||
        0,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "User name",
        accessor: "name",
        sortType: sortTypes.text,
        defaultCanFilter: true,
        Cell: ({ cell: { value } }) => <div>{value}</div>,
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: sortTypes.text,
        defaultCanFilter: true,
        Cell: ({ cell: { value } }) => <div>{value}</div>,
      },
      {
        Header: "Recruiter access",
        accessor: "hasRecruiterRole",
        sortType: sortTypes.boolean,
        Cell: ({ cell: { value, row } }) => (
          <div className="flex justify-center">
            <Switch
              initialState={value}
              switchToggleHandler={getChangeRecruiterRole(row.original)}
            />
          </div>
        ),
      },
    ],
    [users]
  );

  const initialSorting = useMemo(() => [{ id: "hasRecruiterRole" }], []);

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data: users,
      initialState: { sortBy: initialSorting },
      defaultColumn: { Filter: () => null },
    },

    useFilters,
    useSortBy
  );

  return (
    <div className="bg-gray-200 py-8 px-8 h-auto min-h-screen">
      <div className="max-w-6xl bg-white w-full overflow-x py-16 px-8">
        <div className="flex justify-between">
          <div>
            <h4 className="text-h4 italic font-bold">Users</h4>
            <h3 className="text-caption mb-6">
              People who previously logged in to MAD Leads
            </h3>
          </div>
          <SearchByFilter
            searchItem={"name"}
            setFilter={setFilter}
            placeholder={"Search through users"}
          />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <table
            {...getTableProps()}
            className="w-full overflow-x-scroll mb-16"
          >
            <thead className="w-full text-gray-500 font-medium">
              {headerGroups.map((headerGroup) => (
                <tr className="" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={headerStyle}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted && (
                        <span className="relative mt-1 float-right">
                          {column.isSortedDesc ? <Expand /> : <Minimize />}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="px-4 py-3 border">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Button
          disabled={isSaving || !isChanged}
          onClick={saveAccessChanges}
          color="fuchsia"
          name="Save"
        />
        <span className="ml-4"> {message}</span>
      </div>
    </div>
  );
};
