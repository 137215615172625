import { teams as staticTeams } from "../helpers/teams";
import { getToken } from "../helpers/useTestRecruiter";
import { components } from "./schema";

type TeamsType = components["schemas"]["Teams"];

export const createTeam = async (data: TeamsType) => {
  return fetch(
    `${process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"}/teams`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error creating Team",
          message: response.message,
          requestBody: data,
        };
      }
      return response;
    });
};

export const updateTeam = async (
  teamId: string,
  data: { name?: string; short?: string }
) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/teams/${teamId}`,
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating team",
          message: response.message,
        };
      }
      return response;
    });
};
export const deleteTeam = async (teamId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/teams/${teamId}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
};

export const populateDB = async () => {
  staticTeams.forEach(async (team) => {
    await createTeam({ name: team.name, short: team.short });
  });
};
