import { FC, useState } from "react";

import { Note as NoteComponent } from "./Note";
import { Button } from "../../../components/common";
import { NoteEditor } from "./NoteEditor";
import { NoteType } from "./helpers";
import { addNoteToLead, deleteNoteFromLead } from "../../../api/lead";

interface DetailsProps {
  leadId: string;
  notes: NoteType[];
  setNotes: (notes: NoteType[]) => void;
  setMessage: (message: string) => void;
}

export const Notes: FC<DetailsProps> = ({
  leadId,
  notes,
  setMessage,
  setNotes,
}) => {
  const [isNoteEditorOpen, setIsNoteEditorOpen] = useState(false);

  const onSave = async (newNote) => {
    const response = await addNoteToLead(leadId, newNote);
    if (Array.isArray(response)) {
      setNotes(response);
    } else {
      setMessage(response.message || response.error || "Error saving notes");
    }
  };

  const onDelete = async (deletedNoteId) => {
    const response = await deleteNoteFromLead(leadId, deletedNoteId);
    if (Array.isArray(response)) {
      setNotes(response);
    } else {
      setMessage(response.message || response.error || "Error deleting note");
    }
  };

  return (
    <div>
      <h4 className="text-h4 mb-10">Notes</h4>
      <ul className="flex flex-col-reverse">
        {notes?.map((note) => (
          <NoteComponent key={note._id} note={note} onDelete={onDelete} />
        ))}
      </ul>
      <Button
        name="Add note"
        onClick={() => setIsNoteEditorOpen(true)}
        color="fuchsia"
      />
      {isNoteEditorOpen && (
        <NoteEditor
          onSave={onSave}
          onClose={() => setIsNoteEditorOpen(false)}
          isNew={true}
        />
      )}
    </div>
  );
};
