import { FC, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import classNames from "classnames";
import { IndividualPoints } from "../../../helpers/useLeaders";
import { Reward } from "../../reward/Reward";

export const IndividualLeaderboard: FC<{
  data: IndividualPoints[];
}> = ({ data }) => {
  const cashedData = useMemo(
    () => data.sort((a, b) => b.points - a.points),
    [data]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Row",
        accessor: "row",
        Cell: ({ cell: { row } }) => (
          <div className="text-left mr-4">{row.index + 1}.</div>
        ),
      },
      {
        Header: "Personal avatar",
        accessor: "avatar",
        Cell: ({ cell: { row } }) => (
          <div className="relative w-14 mr-4">
            <div className="absolute -translate-y-1/2">
              <Reward
                name={row.original.name}
                employeeScore={row.original.points}
                teamScore={row.original.team_points}
                avatarUrl={row.original.avatar}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value, row } }) => (
          <div
            className={classNames(
              "text-ellipsis",
              "p-4 border border-black mr-4",
              row.original.is_mine ? "bg-lavenderBright" : ""
            )}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Points",
        accessor: "points",
        Cell: ({ cell: { value, row } }) => (
          <div
            className={classNames(
              "p-4 border border-black",
              row.original.is_mine ? "bg-lavenderBright" : ""
            )}
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: cashedData,
      initialState: {
        pageSize: 50,
      },
    },
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;

  return (
    // apply the table props
    <table {...getTableProps()} className="w-full overflow-x-scroll">
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          page.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell, i) => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${i === 2 ? "w-full" : ""} h-14 py-6`}
                      >
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};
