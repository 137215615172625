import { FC } from "react";
import classNames from "classnames";

interface SelectProps {
  errorMessage?: string | undefined;
  inputId?: string;
  title?: string;
  options?: any;
  defaultValue?: string;
  onChange: (event: string) => void;
}

export const Select: FC<SelectProps> = (props) => {
  const { errorMessage, inputId, title, options, defaultValue, onChange } =
    props;
  return (
    <>
      <label className="text-sm mb-2 block" htmlFor={inputId}>
        {title}
      </label>
      <select
        id={inputId}
        defaultValue={defaultValue}
        aria-invalid={errorMessage ? "true" : false}
        className={classNames(
          " w-full border-t-0 border-x-0",
          errorMessage ? "border-lobster" : "border-transparent"
        )}
        onChange={(e) => {
          onChange(e.target.value);
          e.preventDefault();
        }}
      >
        {(Object.keys(options) as Array<keyof typeof options>).map(
          (e, index) => {
            return (
              <option key={`${String(e)}:${index}`} value={options[e].content}>
                {options[e].label}
              </option>
            );
          }
        )}
      </select>
      <span
        role="alert"
        id={`${inputId}-alert`}
        className="mb-6 mt-1 text-lobsterNight"
      >
        {errorMessage}
      </span>
    </>
  );
};
