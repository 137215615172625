import { FC } from "react";

import { getCrownColor } from "../../helpers/reward/rewardHelpers";

export const Crown: FC<{ teamScore: number; className?: string }> = ({
  teamScore,
  className = "",
}) => {
  const crownColor = getCrownColor(teamScore);
  return (
    <div className={className}>
      {crownColor && (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 120 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.357 9.75198C64.3695 9.10729 65.8263 7.22107 65.8263 4.99458C65.8263 2.23615 63.5902 0 60.8318 0C58.0733 0 55.8372 2.23615 55.8372 4.99458C55.8372 7.19528 57.2605 9.06355 59.2368 9.72908L43.4298 35.3964L28.8177 19.5646C29.7856 18.8908 30.419 17.77 30.419 16.5013C30.419 14.4412 28.749 12.7711 26.6889 12.7711C24.6288 12.7711 22.9587 14.4412 22.9587 16.5013C22.9587 18.358 24.3153 19.8978 26.0913 20.1838L20.3192 35.5835L6.12302 26.2506C6.82914 25.6828 7.28106 24.8117 7.28106 23.8351C7.28106 22.1241 5.89408 20.7372 4.18315 20.7372C2.47222 20.7372 1.08524 22.1241 1.08524 23.8351C1.08524 25.2027 1.97149 26.3634 3.20102 26.7741L0.577148 49.5393L0.709951 49.4772H119.178L119.311 49.5393L116.701 26.8949C118.18 26.6612 119.312 25.3803 119.312 23.8351C119.312 22.1241 117.925 20.7372 116.214 20.7372C114.503 20.7372 113.116 22.1241 113.116 23.8351C113.116 24.7075 113.476 25.4957 114.057 26.0587L99.5687 35.5835L93.7568 20.0776C95.2988 19.6198 96.4235 18.1919 96.4235 16.5013C96.4235 14.4412 94.7535 12.7711 92.6934 12.7711C90.6333 12.7711 88.9633 14.4412 88.9633 16.5013C88.9633 17.9054 89.7391 19.1283 90.8854 19.7647L77.4728 34.2969L62.357 9.75198ZM101.717 41.316L102.3 41.5882L101.817 41.5812L101.717 41.316Z"
            fill={crownColor}
          />
        </svg>
      )}
    </div>
  );
};
