import { FC } from "react";
import { Link } from "react-router-dom";

interface Items {
  text: string;
  link: string;
}

interface Props {
  items: Items[];
}
export const LinkList: FC<Props> = ({ items }) => {
  return (
    <>
      <ul className="flex flex-col justify-center">
        {items.map((item, index) => {
          return (
            <li className="mb-5 text-fuchsia underline last:mb-0" key={index}>
              <Link to={item.link}>{item.text}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};
