import { FC, useEffect, useContext } from "react";
import { useState } from "react";
import { StatsBox } from "./StatsBox";
import { DropdownMenu } from "./DropdownMenu";
import { useApi } from "../../../../helpers/useApi";
import { Spinner } from "../../../common/Spinner";
import { BoxColor } from "./PointBox";
import { iteraStructureContext } from "../../../../helpers/useIteraStructureContext";
import { components as APIcomponents } from "../../../../api/schema";

type OfficesStatisticsType = APIcomponents["schemas"]["OfficesStatistics"];

interface Props {
  title: string;
}

function isWhatPercentOf(numA: number, numB: number) {
  return !isNaN((numA / numB) * 100) ? (numA / numB) * 100 : 0;
}

function pushOfficeWithoutRecords(data, officeName: string) {
  data.push({
    total: 0,
    accepted: 0,
    hired: 0,
    in_process: 0,
    office: officeName,
  });
}

function getOfficeIndex(data, officeName: string) {
  if (data) {
    return data.findIndex((element) => element.office == officeName);
  }
}
export function getOfficeId(offices, officeName: string) {
  if (officeName === "All") {
    return "ALL";
  }
  if (offices) {
    const officeObject = offices.find((office) => office.name === officeName);
    return officeObject ? officeObject._id : undefined;
  }
}

export const DashboardStats: FC<Props> = ({ title }) => {
  const { data, isLoading } = useApi<OfficesStatisticsType>("/statistics");
  const { offices } = useContext(iteraStructureContext);

  const officeOptions = [...offices, ...[{ _id: "ALL", name: "All" }]];

  const [officeValue, setOfficeValue] = useState("All");
  const [officeId, setOfficeId] = useState("ALL");
  const [total, setTotal] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [employments, setEmployments] = useState(0);
  const [inProcess, setInProcess] = useState(0);
  const [hirePercentage, setHirePercentage] = useState(0);

  const handleOfficeChange = (event) => {
    setOfficeValue(event.target.value);
    setOfficeId(getOfficeId(offices, event.target.value));
  };

  const resetStats = () => {
    setTotal(0);
    setAccepted(0);
    setEmployments(0);
    setInProcess(0);
    setHirePercentage(0);
  };

  let index = 0;
  useEffect(() => {
    index = getOfficeIndex(data, officeId);

    if (index == -1) {
      pushOfficeWithoutRecords(data, officeId);
      index = getOfficeIndex(data, officeId);
    }

    if (data && data[index]?.total != 0) {
      const hireData = data[index];
      setTotal(hireData.total);
      setAccepted(hireData.accepted);
      setEmployments(hireData.hired);
      const percentage = Math.floor(
        isWhatPercentOf(hireData.hired, hireData.total)
      );
      setHirePercentage(percentage);
      setInProcess(hireData.in_process);
    } else {
      resetStats();
    }
  }, [data, officeId]);

  return (
    <div className="flex flex-col">
      <div className="mx-auto max-w-5xl w-full py-11 lg:px-0">
        <h4 className="text-fuchsia text-h4 mb-10">{title}</h4>
        <DropdownMenu
          label="Choose office"
          options={officeOptions}
          value={officeValue}
          onChange={handleOfficeChange}
          className="mb-11"
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex flex-wrap gap-[27px]">
            <StatsBox number={total} label="Submitted" />
            <StatsBox number={accepted} label="Accepted" />
            <StatsBox number={inProcess} label="Leads in progress" />
            <StatsBox number={employments} label="Employments" />
            <StatsBox
              number={hirePercentage}
              symbol="%"
              label="of leads lead to a hire"
              color={BoxColor.Sky}
            />
          </div>
        )}
      </div>
    </div>
  );
};
