import { useApi } from "./useApi";
import { components } from "../api/schema";
import getQueryString from "./getQueryString";

type PointsItemType = components["schemas"]["PointsItem"];

export interface IndividualPoints extends PointsItemType {
  team_points?: number;
  avatar?: string;
}

export const useLeaders = (
  queryOptions?: object
): {
  individuals: IndividualPoints[];
  teams: PointsItemType[];
  error: boolean | object;
  isLoading: boolean;
} => {
  const { data: teamData, error: teamsPointsRequestError } = useApi<
    PointsItemType[]
  >("/points/teams" + getQueryString(queryOptions));
  const { data: individualData, error: individualsPointsRequestError } = useApi<
    IndividualPoints[]
  >("/points/individuals" + getQueryString(queryOptions));
  const teams = teamData || [];
  const error = teamsPointsRequestError || individualsPointsRequestError;
  const isLoading = !error && (!teamData || !individualData);
  if (error || isLoading) return { individuals: [], teams, isLoading, error };

  const teamsScores = teamData.reduce(
    (acc, team) => ({
      ...acc,
      [team.name]: team.points,
    }),
    {}
  );
  const individuals = individualData.map((individual) => ({
    ...individual,
    team_points: teamsScores[individual?.team ?? ""],
  }));
  return { individuals, teams, error, isLoading };
};
