import { FC } from "react";
import classNames from "classnames";
import { Spinner } from "../../components/common";

import { Link } from "react-router-dom";
import { Path } from "../../routes";
import { PlusTextButton } from "../../components/common/PlusTextButton";

import { PageLayout } from "../../components/layout/Layout";
import { LeadCard } from "../../components/employee/leads";
import { useApi } from "../../helpers/useApi";
import { useMsal } from "@azure/msal-react";
import { components as SchemaComponent } from "../../api/schema";

type UserType = SchemaComponent["schemas"]["User"];
type PointsItemType = SchemaComponent["schemas"]["PointsItem"];

export const MyLeads: FC = () => {
  const { accounts } = useMsal();
  const userId = accounts[0].homeAccountId.split(".")[0];
  const {
    data: user,
    error,
    isLoading: userDataIsLoading,
  } = useApi<UserType>(`/users/${userId}`);
  const { data: userPoints, isLoading: userPointsIsLoading } =
    useApi<PointsItemType>("/points/individuals/own");

  return (
    <PageLayout
      title="Your Submitted Leads"
      description="Here you will find the status of your submitted leads"
    >
      <div className="children:mb-8">
        {userDataIsLoading && <Spinner />}
        {user && error && <h2>Failed to load leads</h2>}
        {userPointsIsLoading && <Spinner />}
        {!userPoints && <h2>Failed to load leads</h2>}
        {user?.leads?.map((el, i: number) => {
          return (
            <div
              key={i}
              className={classNames(
                el.status === "closed" ? "bg-ivory" : "bg-lavenderBright",
                "w-full max-w-6xl mx-auto"
              )}
            >
              <div className="mx-auto w-full py-10 pr-10 pl-5">
                <LeadCard
                  {...el}
                  key={i}
                  leadPoints={
                    userPoints?.leads ? userPoints.leads[i]?.points : 0
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <Link
        to={Path.NEWLEAD}
        className="mx-auto max-w-5xl w-full py-2 px-6 lg:px-0 mb-5"
      >
        <PlusTextButton color="fuchsia" name="Submit new lead" />
      </Link>
    </PageLayout>
  );
};
