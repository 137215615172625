import { FC } from "react";

interface PageLayoutProps {
  title?: string;
  description?: string | boolean;
}
export const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col">
      <div className="mx-auto max-w-5xl w-full px-6 py-11 lg:px-0">
        {title && <h2 className="text-fuchsia text-h2 mb-4">{title}</h2>}
        {description && <h3 className="text-h3">{description}</h3>}
      </div>
      {children}
    </div>
  );
};
