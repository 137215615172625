import { FC, useEffect, useMemo, useState, useRef } from "react";
import { useTable, useFilters, useSortBy } from "react-table";

import { Spinner } from "../../components/common/Spinner";
import { Switch } from "../../components/common/Switch";
import { useApi } from "../../helpers/useApi";
import { Button } from "../../components/common";
import { headerStyle, linkValue } from "./helpers";
import { ReactComponent as Expand } from "../../images/icons/expand.svg";
import { ReactComponent as Minimize } from "../../images/icons/minimize.svg";
import { ToggleClosedLeads } from "../../components/recruiter/dashboard/ToggleClosedLeads";
import { SearchByFilter } from "../../components/common/SearchByFilter/SearchByFilter";
import { SelectColumnFilter } from "./helpers";
import { LeadType } from "../../helpers/sortLeads";
import { capitalize } from "./CandidateDetails/Details";
import { deleteLead } from "../../api/lead";

export const RecruiterDashboard: FC<{ admin: boolean }> = ({ admin }) => {
  const selectedLeads = useRef<string[]>([]);
  const { data: dataLeads, isLoading: isLoadingLeads } =
    useApi<LeadType[]>("/leads");
  const [leads, setLeads] = useState<LeadType[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState("No leads selected");
  const [messageError, setMessageError] = useState("");

  useEffect(() => dataLeads && setLeads(markStaleLead(dataLeads)), [dataLeads]);

  const deleteButton = async () => {
    if (!admin) {
      setMessage("Only admin can delete leads");
      return;
    }
    deleteSelectedLeads(selectedLeads.current, selectedLeads.current.length);
    setIsDeleting(false);
  };

  const deleteSelectedLeads = async (leadsId: string[], len: number) => {
    let leadsDeleted = "";
    let leadsnotDeleted = "";
    const leadsLeftAfterDelete = [...leads];

    for (let i = 0; i < len; i++) {
      const response = await deleteLead(leadsId[i]);
      if (response.message) {
        leadsnotDeleted += " " + response.message + " " + leadsId[i];
        setMessageError("error :" + leadsnotDeleted);
      } else {
        leadsDeleted += " " + response.candidate.name;
      }
    }

    for (let i = 0; i < len; i++) {
      const index = leadsLeftAfterDelete.findIndex(
        (element) => element._id == selectedLeads.current[0]
      );
      leadsLeftAfterDelete.splice(index, 1);
      selectedLeads.current.splice(0, 1);
    }

    setLeads(markStaleLead(leadsLeftAfterDelete));
    setMessage("Successfully deleted lead/s " + leadsDeleted);
  };

  const switchForDeletion =
    ({ _id: id }) =>
    (switchOn: boolean) => {
      if (switchOn) {
        selectedLeads.current.push(id);
      } else {
        const delIndex = selectedLeads.current.indexOf(id);
        if (delIndex != -1) {
          selectedLeads.current.splice(delIndex, 1);
        }
      }
      if (selectedLeads.current.length > 0) {
        setIsDeleting(true);
        setMessage(
          "You are deleting " +
            selectedLeads.current.length.toString() +
            " lead/s"
        );
        setMessageError("");
      } else {
        setIsDeleting(false);
        setMessage("No leads selected");
      }
    };

  const sortTypes = useMemo(
    () => ({
      text: (rowA, rowB, _id: string) =>
        rowA.values[_id]
          .toLowerCase()
          .localeCompare(rowB.values[_id].toLowerCase()),
    }),
    []
  );

  const markStaleLead = (leads: LeadType[]) => {
    const twoWeeksAgo = new Date(Date.now() - 12096e5);
    leads.forEach((lead) => {
      if (
        twoWeeksAgo >
          new Date(lead.updated_at ? lead.updated_at : lead.created_at) &&
        lead.status !== "closed" &&
        lead.status !== "hired"
      ) {
        lead["stale"] = true;
      }
    });
    return leads;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Candidate name",
        accessor: "candidate.name",
        sortType: sortTypes.text,
        defaultCanFilter: false,
        Cell: ({ cell: { value, row } }) => linkValue(value, row),
      },
      {
        Header: "Stale lead",
        accessor: "stale",
        Cell: ({ cell: { value } }) =>
          value ? (
            <div className="font-semibold bg-lobster">{"YES"}</div>
          ) : null,
      },
      {
        Header: "Office",
        accessor: "location.name",
        sortType: sortTypes.text,
        Filter: SelectColumnFilter,
        filter: "includes",
        defaultCanFilter: false,
        Cell: ({ cell: { value } }) => (value ? value : "Not specified"),
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: sortTypes.text,
        Filter: SelectColumnFilter,
        filter: "includes",
        defaultCanFilter: false,
        Cell: ({ cell: { value } }) => (
          <div className="font-semibold ">{capitalize(value)}</div>
        ),
      },
      {
        Header: "Department",
        accessor: "department.name",
        sortType: sortTypes.text,
        Filter: SelectColumnFilter,
        filter: "includes",
        defaultCanFilter: false,
        Cell: ({ cell: { value } }) => (
          <div className="font-semibold ">
            {value ? value : "Not specified"}
          </div>
        ),
      },
      {
        Header: "Lead submitted by",
        accessor: "created_by.name",
        sortType: sortTypes.text,
        Cell: ({ cell: { value } }) => value,
      },
      {
        Header: "Responsible for lead",
        accessor: "responsible.name",
        Cell: ({ cell: { value } }) => (value ? value : "Not specified"),
      },
      {
        Header: "Submitted date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Description",
        accessor: "description",
        sortType: sortTypes.text,
        Cell: ({ cell: { value } }) => value,
      },
      {
        Header: "Lead email",
        accessor: "candidate.email",
        sortType: sortTypes.text,
        Cell: ({ cell: { value } }) => value,
      },
      {
        Header: "Delete lead",
        accessor: "delete",
        Cell: ({ cell: { row, value } }) => (
          <div className="flex justify-center">
            <Switch
              initialState={selectedLeads.current.includes(value)}
              switchToggleHandler={switchForDeletion(row.original)}
            />
          </div>
        ),
      },
    ],
    [leads]
  );

  const initialSorting = useMemo(() => [{ condition: "candidate.name" }], []);

  const filterTypes = useMemo(
    () => ({
      text: (rows, _id: string, filterValue: string) =>
        rows.filter(
          ({ values }) =>
            !values[_id] ||
            String(values[_id])
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
        ),
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data: leads,
      initialState: { sortBy: initialSorting },
      defaultColumn: { Filter: () => null },
      filterTypes,
    },
    useFilters,
    useSortBy
  );

  return (
    <div className="bg-gray-200 py-8 px-8 h-auto min-h-screen">
      <div className="max-w-[1900px] px-7 py-16 bg-white ">
        <h4 className="text-h4 italic font-bold">Candidates</h4>
        <div className="flex justify-between">
          <ToggleClosedLeads
            initialText="Hide closed leads"
            initialState={false}
            setLeads={setLeads}
            data={dataLeads}
          />
          <SearchByFilter
            searchItem={"candidate.name"}
            setFilter={setFilter}
            placeholder={"Search through candidates"}
          />
        </div>
        {isLoadingLeads ? (
          <Spinner />
        ) : (
          <table {...getTableProps()} className="w-full mb-16">
            <thead className="w-full text-gray-500 font-medium">
              {headerGroups.map((headerGroup) => (
                <tr className="" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={headerStyle}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted && (
                        <span className="relative mt-1 float-right">
                          {column.isSortedDesc ? <Expand /> : <Minimize />}
                        </span>
                      )}
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) =>
                      cell ? (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-3 border"
                        >
                          {cell.render("Cell")}
                        </td>
                      ) : (
                        <></>
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className="sticky bottom-0 pb-4">
          <Button
            disabled={!isDeleting}
            onClick={deleteButton}
            color="fuchsia"
            name="Delete"
            type="button"
          />
          <span className="ml-4"> {message}</span>
          <span className="ml-4"> {messageError}</span>
        </div>
      </div>
    </div>
  );
};
