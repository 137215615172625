import { FC, useState } from "react";
import { PageLayout } from "../../components/layout/Layout";
import { FormComplete } from "../../components/employee/leads/formComplete";
import { RegisterNewLead } from "../../components/employee/leads/registerNewLead";

export const NewLead: FC = () => {
  const [formComplete, setFormComplete] = useState(false);

  return (
    <PageLayout
      title={formComplete ? "Submitted" : "Recommend a candidate"}
      description={
        !formComplete && "Who would you like to have as a colleague?"
      }
    >
      <div className="bg-lavenderBright w-full mb-16">
        <div className="mx-auto max-w-5xl w-full py-10">
          {!formComplete && (
            <RegisterNewLead formCompleteCallback={setFormComplete} />
          )}
          {formComplete && <FormComplete />}
        </div>
      </div>
    </PageLayout>
  );
};
