import { FC } from "react";
import { Route, Routes, Navigate } from "react-router";

import { useApi } from "../helpers/useApi";
import useAuth from "../helpers/useTestRecruiter";
import { Dashboard } from "../pages/employee/Dashboard";
import { HowItWorks } from "../pages/employee/HowItWorks";
import { WhoToTipAbout } from "../pages/employee/WhoToTipAbout";
import { MyLeads } from "../pages/employee/MyLeads";
import { NewLead } from "../pages/employee/NewLead";
import { CandidateDetails } from "../pages/recruiter/CandidateDetails/CandidateDetails";
import { RecruiterDashboard } from "../pages/recruiter/Dashboard";
import { UserProfile } from "../pages/employee/UserProfile";
import { Leaderboard } from "../pages/employee/Leaderboard";
import { AccessControl } from "../pages/admin/AccessControl";
import { WhyShouldISubmit } from "../pages/employee/WhyShouldISubmit";
import { AdminStructure } from "../pages/admin/AdminStructure";
import { UserType } from "../components/employee/dashboard/stats/PointsOverview";

export enum Path {
  PROFILE = "/",
  MYLEADS = "/myleads/",
  NEWLEAD = "/newlead/",
  HOWITWORKS = "/how-it-works/:topic",
  POINTSYSTEM = "/how-it-works/point-system",
  WHATHAPPENS = "/how-it-works/what-happens",
  WHYSHOULDISUBMIT = "why-should-i-submit",
  WHOTOTIPABOUT = "/who-to-tip-about",
  CANDIDATEDETAILS = "/candidate/:slug",
  USERPROFILE = "/profile",
  LEADERBOARD = "/leaderboard",
  ACCESS = "/access-control",
  DELETELEAD = "/delete-lead",
  ITERASTRUCTURE = "/structure",
}

const AdminRoute = ({ roles, children }) => {
  if (!roles) return null;
  const isAdmin = roles.includes("admin");
  return isAdmin ? children : <Navigate to="/" replace />;
};

export const Router: FC = () => {
  const [isRecruiter, ...toggleModeAndAccount] = useAuth();
  const account = toggleModeAndAccount[1];
  const { data } = useApi<UserType>(
    `/users/${account?.homeAccountId.split(".")[0]}`
  );

  const isAdmin = data ? data.roles?.includes("admin") : false;

  return (
    <Routes>
      <Route
        path={Path.PROFILE}
        element={
          isRecruiter ? (
            <RecruiterDashboard admin={isAdmin ?? false} />
          ) : (
            <Dashboard />
          )
        }
      />
      <Route path={Path.MYLEADS} element={<MyLeads />} />
      <Route path={Path.NEWLEAD} element={<NewLead />} />
      <Route path={Path.HOWITWORKS} element={<HowItWorks />} />
      <Route path={Path.WHOTOTIPABOUT} element={<WhoToTipAbout />} />
      <Route path={Path.WHYSHOULDISUBMIT} element={<WhyShouldISubmit />} />
      <Route path={Path.CANDIDATEDETAILS} element={<CandidateDetails />} />
      <Route path={Path.USERPROFILE} element={<UserProfile />} />
      <Route path={Path.LEADERBOARD} element={<Leaderboard />} />
      <Route
        path={Path.ACCESS}
        element={
          <AdminRoute roles={data && data.roles}>
            <AccessControl />
          </AdminRoute>
        }
      />
      <Route
        path={Path.ITERASTRUCTURE}
        element={
          <AdminRoute roles={data && data.roles}>
            <AdminStructure />
          </AdminRoute>
        }
      />
    </Routes>
  );
};
