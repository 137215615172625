import { FC } from "react";
import { Link } from "react-router-dom";
import { Path } from "../../routes";
import { useMemo } from "react";
import { savePreferences } from "../../helpers/preferances";
import { capitalize } from "./CandidateDetails/Details";

export const linkValue: FC<{
  (value: string | number, row: object);
}> = (value, row) => {
  return (
    <Link
      className="text-left mr-4 text-sea font-semibold"
      to={Path.CANDIDATEDETAILS.replace(":slug", row.original._id)}
    >
      {value}
    </Link>
  );
};

export const headerStyle =
  "bg-lavenderBright py-3 px-2 border border-gray-200 font-semibold text-left";

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = useMemo<any[]>(() => {
    const uniqueOptions = new Set<string>();
    preFilteredRows.forEach((row) => uniqueOptions.add(row.values[id]));
    return Array.from(uniqueOptions).map((uniqueOption) => ({
      value: uniqueOption,
      text: id === "department" ? uniqueOption : capitalize(uniqueOption),
    }));
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        savePreferences(id, e.target.value);
      }}
      onClick={(e) => e.stopPropagation()} // to prevent sorting
      className="w-full p-1 block"
      style={{
        border: "1px solid #BDBDBD",
        backgroundColor: "inherit",
        width: `${8 * filterValue?.length + 50}px`,
      }}
    >
      <option value="">All</option>
      {options.map(({ value, text }, i) => (
        <option key={i} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};
