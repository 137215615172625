import { FC } from "react";
import { LinkList } from "./LinkList";
import { InfoBox } from "./InfoBox";
import { Link } from "react-router-dom";
import { Path } from "../../../../routes";
import { ArrowTextButton } from "../../../common/ArrowTextButton";

interface Btn {
  text: string;
  link: string;
}

interface DashboardHeaderProps {
  title: string;
  subtitle: string;
  btn: Btn;
}
export const DashboardHeader: FC<DashboardHeaderProps> = ({
  title,
  subtitle,
  btn: { text, link },
}) => {
  const listItems = [
    { text: "Who is Itera looking for?", link: Path.WHOTOTIPABOUT },
    { text: "How it works", link: Path.POINTSYSTEM },
    { text: "What happens after I submit a lead?", link: Path.WHATHAPPENS },
  ];
  return (
    <div className="flex flex-col">
      <div className="mx-auto max-w-5xl w-full py-11 lg:px-0">
        <div className="mb-16">
          <h1 className="text-fuchsia sm:text-7xl text-h1 mb-12">{title}</h1>
          <h4 className="text-fuchsia sm:text-2xl text-h4 mb-12">{subtitle}</h4>
          <Link to={link}>
            <ArrowTextButton color="fuchsia" name={text} />
          </Link>
        </div>
        <div className="mb-16 grid sm:grid-cols-2 grid-cols-1 gap-y-12 sm:gap-y-0">
          <LinkList items={listItems} />
          <InfoBox
            text="When a person is hired, you get a bonus of"
            bonus={25000}
            helpText="*Read the rules for this here"
            helpUrl="https://iteracloud.sharepoint.com/sites/framework/Itera%20Framework/Referral%20Program.aspx"
          />
        </div>
      </div>
    </div>
  );
};
