import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NewLeadInput } from "../employee/leads";
import { components } from "../../api/schema";
import { PlusTextButton } from "../common/PlusTextButton";
import { Spinner } from "../../components/common";
import StructureList from "./StructureList";
import { createOffice } from "../../api/office";

type OfficeDataType = components["schemas"]["Office"];

type Inputs = {
  name: string;
};

export const RegisterNewOffice = ({
  officesIsLoading,
  offices,
  setOffices,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const [formComplete, setFormComplete] = useState(false);
  const [inputOffice, setInputOffice] = useState("");

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setFormComplete(false);
      }, 1500);
    };
  }, [formComplete]);

  const onSubmit = (data) => {
    const officeData: OfficeDataType = {
      name: data.name,
      _id: data._id,
    };
    createOffice(officeData)
      .then((res) => {
        if (res.error) {
          alert(res.error.message ? res.error.message : res.error);
          return;
        }
        setInputOffice(officeData.name);
        const newOffices = [...offices];
        newOffices.push(res);
        setOffices(newOffices);
        setFormComplete(true);
        reset();
      })
      .catch((err) => console.error(err));
    return false;
  };
  return (
    <div className="mt-4">
      <form
        className="px-6 lg:px-0 w-full"
        autoComplete="off"
        id="new-office"
        onSubmit={handleSubmit(onSubmit)}
      >
        <NewLeadInput
          className=" border-b-black w-4/12 block "
          errorMessage={errors.name?.message}
          inputId="name"
          inputPlaceholder="Oslo"
          inputType="text"
          title="Office name:"
          register={() =>
            register("name", {
              required: "Name is required",
            })
          }
        />
        <div className="block py-4">
          <PlusTextButton color="lobster" name="Add office" />
        </div>
        {formComplete ? "Office '" + inputOffice + "' added!" : ""}
      </form>
      {officesIsLoading ? (
        <Spinner />
      ) : (
        <StructureList structure={offices} title="offices" type="office" />
      )}
    </div>
  );
};
