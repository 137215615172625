export enum Rewards {
  None = 0,
  Bronze = 50000,
  Silver = 125000,
  Gold = 250000,
  Platinum = 500000,
  Diamond = 1000000,
}
export enum TeamRewards {
  None = 0,
  Bronze = 250000,
  Silver = 500000,
  Gold = 1000000,
}

export const calculateLevel = (score = 0) =>
  (score >= Rewards.Diamond && Rewards.Diamond) ||
  (score >= Rewards.Platinum && Rewards.Platinum) ||
  (score >= Rewards.Gold && Rewards.Gold) ||
  (score >= Rewards.Silver && Rewards.Silver) ||
  (score >= Rewards.Bronze && Rewards.Bronze) ||
  Rewards.None;

export const getColorName = (level: Rewards) =>
  ({
    [Rewards.None]: "",
    [Rewards.Bronze]: "peach",
    [Rewards.Silver]: "grey",
    [Rewards.Gold]: "gold",
    [Rewards.Platinum]: "sky",
    [Rewards.Diamond]: "lavender",
  }[level]);

export const getBorderColor = (level: Rewards) => {
  const color = getColorName(level);
  return color ? `border-${color}` : "";
};

export const getTextColor = (level: Rewards) => {
  const color = getColorName(level);
  return color ? `text-${color}` : "";
};

export const getLevelName = (level: Rewards) =>
  ({
    [Rewards.None]: "",
    [Rewards.Bronze]: "Bronse",
    [Rewards.Silver]: "Sølv",
    [Rewards.Gold]: "Gull",
    [Rewards.Platinum]: "Platina",
    [Rewards.Diamond]: "Diamant",
  }[level]);

export const getStarCount = (level: Rewards) =>
  ({
    [Rewards.None]: 0,
    [Rewards.Bronze]: 1,
    [Rewards.Silver]: 2,
    [Rewards.Gold]: 3,
    [Rewards.Platinum]: 4,
    [Rewards.Diamond]: 5,
  }[level]);

export const getColor = (level: Rewards) =>
  ({
    [Rewards.None]: "inherit",
    [Rewards.Bronze]: "#E8907D",
    [Rewards.Silver]: "#BDBDBD",
    [Rewards.Gold]: "#ffdd00",
    [Rewards.Platinum]: "#5E9BF6",
    [Rewards.Diamond]: "#977de8",
  }[level]);

export const getCrownColor = (teamScore = 0) =>
  (teamScore >= TeamRewards.Gold && getColor(Rewards.Gold)) ||
  (teamScore >= TeamRewards.Silver && getColor(Rewards.Silver)) ||
  (teamScore >= TeamRewards.Bronze && getColor(Rewards.Bronze)) ||
  "";
