import { getToken } from "./../helpers/useTestRecruiter";
import { components } from "./schema";

type DepartmentType = components["schemas"]["Department"];

export const createDepartment = async (data: DepartmentType) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/department`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error creating Department",
          message: response.message,
          requestBody: data,
        };
      }
      return response;
    });
};

export const updateDepartment = async (
  departmentId: string,
  data: { name?: string; short?: string }
) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/department/${departmentId}`,
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating department",
          message: response.message,
        };
      }
      return response;
    });
};
export const deleteDepartment = async (departmentId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/department/${departmentId}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
};
