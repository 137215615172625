import { getToken } from "./useTestRecruiter";
import useSWR, { Key } from "swr";
import fetch from "unfetch";

export enum HTTP_REQUEST_TYPE {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

const apiUrl = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";

export const fetcher = (url: string) => {
  const token = getToken();
  return fetch(apiUrl + url, {
    method: HTTP_REQUEST_TYPE.GET,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((r) => {
    if (r.ok) {
      return r.json();
    } else {
      return r.json().then((e) => {
        throw e;
      });
    }
  });
};

export const useApi = <T>(url: Key) => {
  const { data, error } = useSWR(url, fetcher, {
    errorRetryInterval: 1000,
    errorRetryCount: 10,
  });
  const isLoading = !data && !error;
  return { data, error, isLoading } as {
    data: T;
    error: object;
    isLoading: boolean;
  };
};
