import { getToken } from "./../helpers/useTestRecruiter";
import { components } from "./schema";

type Office = components["schemas"]["Office"];

export const createOffice = async (office: Office) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/offices`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(office),
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error creating office",
          message: response.message,
        };
      }
      return response;
    });
};

export const deleteOffice = async (officeId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/offices/${officeId}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
};

export const updateOffice = async (
  officeId: string,
  data: { name?: string }
) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/offices/${officeId}`,
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating department",
          message: response.message,
        };
      }
      return response;
    });
};
