import { FC, useContext } from "react";
import { populateDB } from "../../api/teams";
import { RegisterNewDepartment } from "../../components/admin/RegisterNewDepartment";
import { RegisterNewOffice } from "../../components/admin/RegisterNewOffice";
import { RegisterNewTeam } from "../../components/admin/RegisterNewTeam";
import { Button } from "../../components/common";
import { iteraStructureContext } from "../../helpers/useIteraStructureContext";

export const AdminStructure: FC = () => {
  const {
    offices,
    officesIsLoading,
    departments,
    departmentsIsLoading,
    setOffices,
    setDepartments,
    teams,
    setTeams,
    teamsIsLoading,
  } = useContext(iteraStructureContext);

  const populateDatabase = async () => {
    await populateDB();
  };

  return (
    <div className="bg-gray-200 py-8 px-8 min-h-screen flex flex-row gap-4 ">
      <div className="max-w-3xl bg-white w-full overflow-x py-8 px-8 h-[50%]">
        <h3 className="text-h3">Office</h3>
        <RegisterNewOffice
          officesIsLoading={officesIsLoading}
          offices={offices}
          setOffices={setOffices}
        />
      </div>
      <div className="max-w-3xl bg-white w-full overflow-x py-8 px-8 h-2/3">
        <h3 className="text-h3">Departments</h3>
        <RegisterNewDepartment
          departmentsIsLoading={departmentsIsLoading}
          departments={departments}
          setDepartments={setDepartments}
        />
      </div>
      <div className="max-w-3xl bg-white w-full overflow-x py-8 px-8 h-2/3">
        <h3 className="text-h3">Teams</h3>
        {teams.length === 0 && teamsIsLoading === false ? (
          <div className="block py-4">
            <Button
              color="lobster"
              name="Populate database with teams"
              type="button"
              onClick={() => populateDatabase()}
            />
          </div>
        ) : (
          <></>
        )}
        {
          <RegisterNewTeam
            teamsIsLoading={teamsIsLoading}
            teams={teams}
            setTeams={setTeams}
          />
        }
      </div>
    </div>
  );
};
