import { getUserId } from "./useTestRecruiter";

const getPrferenceKey = () => {
  const userId = getUserId();
  if (userId) return userId + "-preferences";
  return "preferences";
};

export interface Preference {
  id: string;
  value: any;
}

export const getPreferences = (): Preference[] => {
  const preferencesJSON = localStorage.getItem(getPrferenceKey());
  if (preferencesJSON)
    try {
      const preferences = JSON.parse(preferencesJSON);
      if (Array.isArray(preferences)) return preferences;
    } catch (error) {
      console.log(error);
    }
  return [];
};

export const savePreferences = (id, value) => {
  const preferences = getPreferences();
  const existedPreference = preferences.find(
    (preference) => id === preference.id
  );
  if (existedPreference) {
    existedPreference.value = value;
  } else {
    preferences.push({ id, value });
  }
  localStorage.setItem(getPrferenceKey(), JSON.stringify(preferences));
};
