import { NoteType } from "./../pages/recruiter/CandidateDetails/helpers";
import { getToken } from "./../helpers/useTestRecruiter";
import { components } from "./schema";
import { STATUS } from "../helpers/statuses";
type LeadDataType = components["schemas"]["CreateLead"];

export const createLead = async (data: LeadDataType) => {
  return fetch(
    `${process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"}/leads`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error creating lead",
          message: response.message,
          requestBody: data,
        };
      }
      return response;
    });
};

export const updateLead = async (leadId: string, data) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/leads/${leadId}`,
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating leads",
          message: response.message,
        };
      }
      return response;
    });
};

export const addNoteToLead = async (leadId: string, note: NoteType) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/leads/${leadId}/notes`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify(note),
    }
  ).then((response) => response.json());
};

export const deleteNoteFromLead = async (leadId: string, noteId: string) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/leads/${leadId}/notes/${noteId}`,
    {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
    }
  ).then((response) => response.json());
};

export const deleteLead = async (leadId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/leads/${leadId}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
};

export const updateLeadStatus = (leadId: string, status?: STATUS) => {
  return fetch(
    `${
      process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1"
    }/leads/${leadId}/status`,
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + getToken() ?? "",
      },
      body: JSON.stringify({
        status: status,
      }),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating leads",
          message: response.message,
        };
      }
      return response;
    });
};
