import { FC, useState } from "react";

const TRANSITION_DELAY = 200;

export const Switch: FC<{
  initialState: boolean;
  switchToggleHandler: (flag: boolean) => void;
}> = ({ initialState = false, switchToggleHandler }) => {
  const [switchOn, setSwitchOn] = useState(initialState);
  const TrackClassName = "w-10 h-5 px-0.5 rounded-full flex items-center";
  const ThumbClassName = "w-4 h-4 bg-white rounded-full";
  const toggleSwitch = () => {
    switchToggleHandler(!switchOn);
    setSwitchOn(!switchOn);
  };

  return (
    <div
      className={TrackClassName}
      style={{ backgroundColor: switchOn ? "#AF1DFF" : "#6F6E6B" }}
      onClick={toggleSwitch}
    >
      <div
        className={ThumbClassName}
        style={{
          transition: `all ${TRANSITION_DELAY}ms`,
          marginLeft: switchOn ? 20 : 1,
        }}
      />
    </div>
  );
};
