import { FC } from "react";
import classNames from "classnames";

export const StatusIndicator: FC<{
  className?: string;
  content?: string;
  disabled?: boolean;
}> = ({ className, content, disabled }) => (
  <div
    className={classNames(
      "rounded-full text-white text-h3 flex items-center justify-center",
      disabled ? "bg-gray-500" : "bg-fuchsia",
      className
    )}
  >
    {content}
  </div>
);
