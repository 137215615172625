import { useMsal } from "@azure/msal-react";
import { FC, useEffect, useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { TwoColumnLayout } from "../../components/layout/TwoColumnLayout";
import { useApi } from "../../helpers/useApi";
import { getUserId } from "../../helpers/useTestRecruiter";

import { components as SchemaComponent } from "../../api/schema";
import { AvatarSelection } from "../../components/common/Avatar/AvatarSelection";
import { RewardGroup } from "../../components/reward/RewardGroup";
import { RewardPreview } from "../../components/reward/RewardPreview";
import { Rewards, TeamRewards } from "../../helpers/reward/rewardHelpers";
import { Spinner } from "../../components/common/Spinner";
import { Button } from "../../components/common";
import { iteraStructureContext } from "../../helpers/useIteraStructureContext";
import { updateUser, UserDataType } from "../../api/user";
import { UserType } from "../../components/employee/dashboard/stats/PointsOverview";

export type TeamType = SchemaComponent["schemas"]["Team"];
type IteraTeam = SchemaComponent["schemas"]["Teams"];
type Inputs = {
  showName: boolean;
  fakeName: string;
  team: TeamType;
};

export const UserProfile: FC = () => {
  const [showRealName, setShowRealName] = useState(true);
  const [fakeName, setFakeName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [teamStatus, setTeamStatus] = useState<IteraTeam>();
  const { handleSubmit, register } = useForm<Inputs>();
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const { accounts } = useMsal();
  const userId = accounts[0].homeAccountId.split(".")[0];
  const { data, isLoading } = useApi<UserType>(`/users/${userId}`);
  const { teams, getTeams } = useContext(iteraStructureContext);

  if (isLoading) return <Spinner />;

  const employeePoints = 2000;

  useEffect(() => {
    if (data) {
      setShowRealName(data.show_name ? data.show_name : false);
      setFakeName(data.fake_name ? data.fake_name : "");
      setTeamStatus(getTeams(data.team ? data.team : ""));
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  }, [visible]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const userId = getUserId();
    const userData: UserDataType = {
      show_name: showRealName,
      fake_name: data.fakeName,
      avatar: avatar.toString(),
      team: data.team,
    };
    if (userId != null) {
      updateUser(userId, userData)
        .then((res) => {
          if (res.error) {
            alert(res.message);
            return;
          }
          setMessage("User updated 🙌");
          setVisible(true);
          setDisabledSave(true);
        })
        .catch((err) => console.log(err));
    } else {
      setMessage("Failed to add a nickname");
      setVisible(true);
      setDisabledSave(true);
      return;
    }
  };
  const updateAvatar = (avatarUrl: string) => {
    setAvatar(avatarUrl);
    setDisabledSave(false);
  };

  return (
    <div className="relative">
      <span className="absolute right-14 -top-12">
        Your points: {employeePoints}
      </span>
      <TwoColumnLayout
        headlines={{
          left: "Your profile and settings",
          right: "Point rewards",
        }}
        left={
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="">Which team are you on?</p>
            <div className="mb-8">
              <select
                className="w-full border-0 border-b-2"
                {...register("team")}
                onChange={() => setDisabledSave(false)}
                defaultValue={teamStatus != undefined ? teamStatus.short : ""}
              >
                {teamStatus == undefined && (
                  <option key="empty" value="">
                    Select Team
                  </option>
                )}
                {teams.map((team) => {
                  return (
                    <option
                      key={team.short}
                      value={team.short}
                      selected={teamStatus && teamStatus.short === team.short}
                    >
                      {team.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <p className="">
              Do you want to show your real name on the leaderboard?
            </p>
            <div className="mt-5 flex space-x-20">
              <div>
                <input
                  {...register("showName")}
                  type="radio"
                  className="!p-0 w-6 h-6  text-fuchsia"
                  name="showName"
                  checked={showRealName}
                  value={1}
                  onChange={() => {
                    setShowRealName(true);
                    setDisabledSave(false);
                  }}
                />
                <label className="text-sm ml-2 text-lobsterNight">Yes</label>
              </div>
              <div>
                <input
                  {...register("showName")}
                  type="radio"
                  className="!p-0 w-6 h-6 text-fuchsia"
                  name="showName"
                  checked={!showRealName ? true : false}
                  value={0}
                  onChange={() => {
                    setShowRealName(false);
                    setDisabledSave(false);
                  }}
                />
                <label className="text-sm ml-2">No</label>
              </div>
            </div>
            {!showRealName && (
              <div className="mt-11">
                <p className="">What name do you want to use?</p>
                <input
                  className=" w-full placeholder:text-lobsterNight"
                  type="text"
                  placeholder="Name"
                  {...register("fakeName", {
                    required: "Nickname is required",
                  })}
                  defaultValue={fakeName}
                  onBlur={() => setDisabledSave(false)}
                />
              </div>
            )}
            <AvatarSelection
              data={data}
              onUpdateAvatar={(avatarUrl) => updateAvatar(avatarUrl)}
            />
            <Button
              id="submit"
              disabled={disabledSave}
              name="Save"
              color="fuchsia"
              type="submit"
            />

            {visible && <p className="mt-5">{message}</p>}
          </form>
        }
        right={
          <>
            <div className="">
              <p>Unlock avatar frames as you gather points.</p>
              <p>The frames will be changed automatically as you unlock it.</p>
            </div>
            <RewardGroup>
              {Object.values(Rewards)
                .slice(-5)
                .map(Number)
                .map((employeeScore) => (
                  <RewardPreview
                    key={`employeeReward${employeeScore}`}
                    employeeScore={employeeScore}
                    locked={employeePoints < employeeScore}
                  />
                ))}
            </RewardGroup>
            <div className=" mb-6">
              <p>
                Your team unlocks decorations as you gather points as a group.
              </p>
              <p>The deco will be added automatically as you unlock it.</p>
            </div>
            <RewardGroup>
              {Object.values(TeamRewards)
                .slice(-3)
                .map(Number)
                .map((teamScore) => (
                  <RewardPreview
                    key={`teamReward${teamScore}`}
                    teamScore={teamScore}
                    locked={false}
                  />
                ))}
            </RewardGroup>
          </>
        }
      />
    </div>
  );
};
