import { FC, useContext, useEffect, useState } from "react";

import { LeadModal, ModalData } from "./LeadModal";

import { ReactComponent as EditIcon } from "../../../images/icons/edit_icon.svg";
import { ReadMore } from "./ReadMore";
import { useApi } from "../../../helpers/useApi";
import useAuth from "../../../helpers/useTestRecruiter";
import { LeadType } from "../../../helpers/sortLeads";
import { DepartmentType } from "../../../components/employee/leads/registerNewLead/RegisterNewLead";
import { iteraStructureContext } from "../../../helpers/useIteraStructureContext";
import { components as APIcomponents } from "../../../api/schema";
import { updateLead } from "../../../api/lead";

type UserType = APIcomponents["schemas"]["User"];
type UsersType = APIcomponents["schemas"]["UserList"];

interface DetailsProps {
  lead: LeadType;
  setMessage: (value: string) => void;
  setLead: (value: LeadType) => void;
}

interface DetailsLineItemProps {
  description: string;
  content: JSX.Element;
}

export const capitalize = (s: string | undefined): string | null => {
  if (typeof s !== "string") return null;
  return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
};

const LineItem: FC<DetailsLineItemProps> = ({ description, content }) => {
  return (
    <li className=" text-gray-500">
      {description}: {content}
    </li>
  );
};

export const Details: FC<DetailsProps> = ({ lead, setMessage, setLead }) => {
  const [editing, setEditing] = useState(false);
  const [, ...toggleModeAndAccount] = useAuth();
  const account = toggleModeAndAccount[1];
  const { data: user, error } = useApi<UserType>(
    `/users/${account?.homeAccountId?.split(".")[0]}`
  ) || { data: {}, error: {} };

  const isAdmin = !error && user?.roles?.includes("admin");

  const { data: usersData, isLoading: isLoadingUsers } =
    useApi<UsersType>("/users");

  const [users, setUsers] = useState([]);
  const [recruitersAndAdmins, setRecruitersAndAdmins] = useState([]);
  const { offices, departments, pcs } = useContext(iteraStructureContext);

  useEffect(() => {
    usersData &&
      !isLoadingUsers &&
      isAdmin &&
      setUsers(
        Object.assign([
          ...usersData.map((user: UserType) => {
            return { _id: user._id, name: user.name };
          }),
        ])
      );
    usersData &&
      !isLoadingUsers &&
      isAdmin &&
      setRecruitersAndAdmins(
        Object.assign([
          ...usersData.filter((user: UserType) =>
            user.roles?.includes("recruiter")
          ),
        ])
      );
  }, [usersData]);

  const onSaveDetailsHandler = async (updatedData: ModalData) => {
    const response = await updateLead(lead._id, {
      ...updatedData,
      created_by: isAdmin
        ? usersData.find((user) => user._id === updatedData.created_by)
            ?.external_id
        : lead.created_by.external_id,
      responsible: isAdmin
        ? usersData.find(
            (user) =>
              user.external_id.replace(/-/g, "") === updatedData.responsible
          )
        : "",
      department: isAdmin
        ? departments.find(
            (department: DepartmentType) =>
              department._id === updatedData.department
          )?._id
        : "",
      location: isAdmin
        ? offices.find((office) => office._id === updatedData.location)?._id
        : "",
      pcs: isAdmin
        ? pcs.find((level) => level._id === updatedData.pcs)?._id
        : "",
    });
    if (response.error) {
      setMessage(response.message || response.error);
    } else {
      setLead(response);
      setEditing(false);
    }
  };

  return (
    <>
      {editing && (
        <LeadModal
          lead={lead}
          onClose={() => setEditing(false)}
          onSave={onSaveDetailsHandler}
          users={users}
          recruiters={recruitersAndAdmins}
        />
      )}
      <div className="flex justify-between flex-col sm:flex-row">
        <div className="w-80 mr-10">
          <span className="flex flex-row items-center">
            <h4 className="flex-1 text-h4 mb-2">Details</h4>
            <button
              className="underline flex gap-x-2 cursor-pointer"
              onClick={() => setEditing(true)}
            >
              <EditIcon />
              Edit
            </button>
          </span>
          <ul>
            <LineItem
              description="Name"
              content={
                <span className="text-black">{lead.candidate.name}</span>
              }
            />
            <LineItem
              description="Linkedin URL"
              content={
                <a
                  href={lead.candidate.linkedin_url ?? ""}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sea"
                >
                  {lead.candidate.linkedin_url ?? "Missing url"}
                </a>
              }
            />
            <LineItem
              description="E-mail"
              content={
                <a
                  href={`mailto:${lead.candidate.email}`}
                  className="text-fuchsia"
                >
                  {lead.candidate.email ?? "Missing email"}
                </a>
              }
            />
            <LineItem
              description="Mobile"
              content={
                <a href={`tel:${lead.candidate.phone}`} className="text-sea">
                  {lead.candidate.phone ?? "Missing phonenumber"}
                </a>
              }
            />
            <LineItem
              description="Office"
              content={
                <span className="text-black capitalize">
                  {capitalize(lead.location?.name) ?? "Missing office"}
                </span>
              }
            />
            <LineItem
              description="Department"
              content={
                <span className="text-black">{lead.department?.name}</span>
              }
            />
            <LineItem
              description="PCS Level"
              content={<span className="text-black">{lead.pcs}</span>}
            />
            <LineItem
              description="Lead submitted by"
              content={
                <span className="text-black">{lead.created_by.name}</span>
              }
            />

            {lead.on_behalf_of !== "" && (
              <LineItem
                description="Lead submitted on behalf of"
                content={
                  <span className="text-black">{lead.on_behalf_of}</span>
                }
              />
            )}
            {lead.responsible?.name !== "" && (
              <LineItem
                description="Responsible for lead"
                content={
                  <span className="text-black">{lead.responsible?.name}</span>
                }
              />
            )}
            <LineItem
              description="Date of submission"
              content={
                <span className="text-black">
                  {new Date(lead.created_at ?? "").toDateString()}
                </span>
              }
            />
          </ul>
        </div>
        <div className="w-full sm:w-1/2">
          <h4 className="text-gray-500 mb-2">Description (From lead)</h4>
          <ReadMore
            className="min-h-0 sm:min-h-[254px] flex-col"
            message={lead.description || ""}
            textLimit={300}
          />
        </div>
      </div>
    </>
  );
};
