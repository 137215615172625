import { FC, ReactNode } from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

import { useApi } from "../../helpers/useApi";
import { TopBar } from ".";
import useAuth from "../../helpers/useTestRecruiter";
import { Path } from "../../routes";
import { getToken } from "../../helpers/useTestRecruiter";
import { Button } from "../../components/common";
import { UserType } from "../employee/dashboard/stats/PointsOverview";

interface NavMenuItemProps {
  children: ReactNode;
  path: Path;
}
export const Menu: FC = () => {
  const [isRecruiter, ...toggleModeAndAccount] = useAuth();
  const account = toggleModeAndAccount[1];
  const { data, error } = useApi<UserType>(
    `/users/${account?.homeAccountId.split(".")[0]}`
  );

  const isAdmin = data && !error && data.roles?.includes("admin");

  const NavMenuItem: FC<NavMenuItemProps> = ({ children, path }) => {
    const resolved = useResolvedPath(path);
    const match = useMatch({ path: resolved.pathname, end: true });
    return (
      <NavLink
        caseSensitive
        role="button"
        to={path}
        style={{
          borderBottomColor: match ? "#af1dff" : "",
          borderBottomWidth: "4px",
        }}
        className="text-base border-white px-6 py-3 border-b-4"
      >
        {children}
      </NavLink>
    );
  };

  const BearerButton: FC = () => (
    <Button
      color="fuchsia"
      onClick={() => copyBearerToClipboard()}
      name="Copy bearer"
    ></Button>
  );

  const copyBearerToClipboard = () => {
    const bearerText = getToken();
    const tempInput = document.createElement("INPUT") as HTMLInputElement;
    document.body.appendChild(tempInput);
    tempInput.setAttribute("value", `${bearerText}`);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  return (
    <header className="flex flex-col">
      <TopBar />
      <nav className="flex flex-row flex-wrap px-6 py-0 justify-start border-b-2 border-b-ivory">
        {!isRecruiter && (
          <>
            <NavMenuItem path={Path.PROFILE}>Dashboard</NavMenuItem>
            <NavMenuItem path={Path.NEWLEAD}>New lead</NavMenuItem>
            <NavMenuItem path={Path.MYLEADS}>Your leads</NavMenuItem>
            <NavMenuItem path={Path.LEADERBOARD}>Leaderboard</NavMenuItem>
            <NavMenuItem path={Path.WHOTOTIPABOUT}>
              Who to tip about
            </NavMenuItem>
            <NavMenuItem path={Path.HOWITWORKS}>How it works</NavMenuItem>
            <NavMenuItem path={Path.WHYSHOULDISUBMIT}>
              Why should I submit a lead?
            </NavMenuItem>
          </>
        )}
        {isRecruiter && (
          <>
            <NavMenuItem path={Path.PROFILE}>Dashboard</NavMenuItem>
            {isAdmin && (
              <>
                <NavMenuItem path={Path.ACCESS}>Access control</NavMenuItem>
                <NavMenuItem path={Path.ITERASTRUCTURE}>
                  Admin structure
                </NavMenuItem>
              </>
            )}
          </>
        )}
        {process.env.NODE_ENV !== "production" && <BearerButton />}
      </nav>
    </header>
  );
};
