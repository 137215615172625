import { FC } from "react";

import { calculateLevel, getColor } from "../../helpers/reward/rewardHelpers";
import { Crown } from "./Crown";
import { Stars } from "./Stars";

export const Reward: FC<{
  name?: string;
  employeeScore?: number;
  teamScore?: number;
  avatarUrl?: string;
}> = ({ name = "", employeeScore = 0, teamScore = 0, avatarUrl }) => {
  const employeeLevel = calculateLevel(employeeScore);
  const borderColor = getColor(employeeLevel);

  return (
    <div className="flex flex-col items-center relative">
      <Crown className="absolute -top-4 h-4" teamScore={teamScore} />
      <div
        className="flex justify-center items-center border-3 w-14 h-14"
        style={{ borderColor }}
      >
        {avatarUrl && avatarUrl !== "3" && (
          <img
            className="object-cover w-full h-full"
            src={avatarUrl}
            alt={`${name} avatar`}
          />
        )}
      </div>
      <Stars
        className="absolute -bottom-4 flex h-4"
        employeeScore={employeeScore}
      />
    </div>
  );
};
