import { useMsal } from "@azure/msal-react";
import React, { FC, useEffect, useState } from "react";
import { Avatar } from "./Avatar";

interface AvatarSelectionProps {
  data: any;
  onUpdateAvatar: (avatar: string) => void;
}

export const AvatarSelection: FC<AvatarSelectionProps> = ({
  data,
  onUpdateAvatar,
}) => {
  const { accounts } = useMsal();
  const [avatarIndex, setAvatarIndex] = useState(0);
  const username = accounts[0]?.username?.split("@")[0];

  const personalAvatar = `https://imageiterastorage.blob.core.windows.net/images-prod/${username}.jpg?sv=2020-02-10&ss=b&srt=sco&sp=rlx&se=2024-02-01T16:04:40Z&st=2021-05-17T07:04:40Z&spr=https&sig=Vc0655DnQG2c7JdAmniMP9HivWju50to6Zzq%2FN6df1s%3D`;
  const rootAvatarUrl = location.protocol + "//" + location.host + "/avatars/";

  const getAvatarIndex = (url: string) => {
    const lastNumberInString = /(\d+)(?!.*\d)/;
    if (url.includes("imageiterastorage")) return 0;
    return Number(url.match(lastNumberInString)?.[0]);
  };

  useEffect(() => {
    if (data) {
      setAvatarIndex(getAvatarIndex(data.avatar));
    }
  }, [data]);

  const handleAvatarClick = (avatarUrl: string, avatarIndex: number) => {
    setAvatarIndex(avatarIndex);
    onUpdateAvatar(avatarUrl);
  };

  return (
    <div className="mt-11">
      <div>Choose avatar</div>
      <div className="flex flex-wrap mt-4 w-80 gap-4 my-10">
        <Avatar
          avatarUrl={personalAvatar}
          active={avatarIndex === 0}
          onClick={() => handleAvatarClick(personalAvatar, 0)}
        />
        {Array.from({ length: 7 }, (_, index) => {
          const avatarUrl = rootAvatarUrl + `avatar-${index + 1}.svg`;
          return (
            <Avatar
              key={index + 1}
              avatarUrl={avatarUrl}
              active={index + 1 === avatarIndex}
              onClick={() => handleAvatarClick(avatarUrl, index + 1)}
            />
          );
        })}
      </div>
    </div>
  );
};
