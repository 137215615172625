import React, { FC } from "react";
import classNames from "classnames";

export interface AvatarProps {
  avatarUrl: string;
  active?: boolean;
  onClick?: () => void;
}

export const Avatar: FC<AvatarProps> = ({ avatarUrl, active, onClick }) => {
  return (
    <div
      className={classNames(
        active ? "border-fuchsia" : "border-ivory hover:border-lavenderBright",
        "w-16 h-16 border-3"
      )}
      onClick={onClick}
    >
      <img
        className="object-contain w-full h-full"
        src={avatarUrl}
        alt="avatar-test"
      />
    </div>
  );
};
