import { FC } from "react";

import { Reward } from "./Reward";
import {
  calculateLevel,
  getColor,
  getLevelName,
} from "../../helpers/reward/rewardHelpers";

export interface RewardProp {
  employeeScore?: number;
  teamScore?: number;
  locked?: boolean;
}

const LockedSVG = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24.5C13.65 24.5 15 23.15 15 21.5C15 19.85 13.65 18.5 12 18.5C10.35 18.5 9 19.85 9 21.5C9 23.15 10.35 24.5 12 24.5ZM21 11H19.5V8C19.5 3.86 16.14 0.5 12 0.5C7.86 0.5 4.5 3.86 4.5 8V11H3C1.35 11 0 12.35 0 14V29C0 30.65 1.35 32 3 32H21C22.65 32 24 30.65 24 29V14C24 12.35 22.65 11 21 11ZM7.35 8C7.35 5.435 9.435 3.35 12 3.35C14.565 3.35 16.65 5.435 16.65 8V11H7.35V8ZM21 29H3V14H21V29Z"
      fill="black"
    />
  </svg>
);

export const RewardPreview: FC<RewardProp> = ({
  employeeScore,
  teamScore,
  locked,
}) => {
  const level = calculateLevel(employeeScore);
  const levelName = getLevelName(level);
  const color = getColor(level);
  const isTeam = teamScore !== undefined && employeeScore === undefined;

  return (
    <div className="flex flex-col items-center">
      <span style={{ color }}>{levelName}</span>
      <Reward employeeScore={employeeScore} teamScore={teamScore}>
        {locked && <LockedSVG />}
      </Reward>
      <span className={"mt-4"}>{isTeam ? teamScore : employeeScore} p</span>
    </div>
  );
};
