import { getToken } from "./../helpers/useTestRecruiter";
import { components } from "./schema";

export type UserDataType = components["schemas"]["UpdateUser"];

export const getUser = async (userId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/users/${userId}`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error getting user",
          message: response.message,
        };
      }
      return response;
    });
};

export const createUser = async (userId: string) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/users/${userId}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error creating user",
          message: response.message,
        };
      }
      return response;
    });
};

export const updateUser = async (userId: string, userData: UserDataType) => {
  const host = process.env.REACT_APP_API_URL ?? "http://localhost:5555/api/v1";
  return fetch(`${host}/users/${userId}`, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(userData),
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._id) {
        return {
          error: "Error updating user",
          message: response.message,
        };
      }
      return response;
    });
};
