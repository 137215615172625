import { ChangeEventHandler, FC } from "react";
import classNames from "classnames";

type OptionsType = {
  name: string;
  short?: string;
  _id?: string;
};
interface Props {
  label: string;
  value: string;
  options: OptionsType[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  className?: string;
}

export const DropdownMenu: FC<Props> = ({
  label,
  value,
  options,
  onChange,
  className,
}) => {
  return (
    <div
      className={classNames(
        "max-w-[260px] lg:mr-8 mt-8 mb-50 bottom-0 top-32 right-0 focus:border-fuchsia",
        className
      )}
    >
      <p>{label}</p>
      <div>
        <select
          className="mt-2 w-full border-b-1 border-0 bg-white"
          value={value}
          onChange={onChange}
        >
          {options.map((option) => (
            <option key={option._id} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
