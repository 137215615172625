import { FC } from "react";
import { format } from "date-fns";

import { ReadMore } from "./ReadMore";
import { Button } from "../../../components/common";
import { NoteType } from "./helpers";

interface DetailsProps {
  note: NoteType;
  onDelete: (deletedNoteId: string) => void;
}

export const Note: FC<DetailsProps> = ({ note, onDelete }) => {
  const { created_at: date, text, title, _id: noteId } = note;
  const { name, fake_name, show_name } = note.created_by || {};
  const nameToShow = show_name ? name : fake_name;

  return (
    <li className="mb-12">
      <div className="flex flex-wrap justify-between mb-4 gap-x-8">
        <div className="w-full xl:w-fit xl:grow">
          {title || "Title of note"}
        </div>
        <span>
          <span className="text-gray-500 mr-2">Note by:</span>
          {nameToShow}
        </span>
        <span>
          <span className="text-gray-500 mr-2">Date:</span>
          {date && format(new Date(date), "MM.dd.yyyy")}
        </span>
        <Button
          onClick={() => onDelete(String(noteId))}
          name="Delete"
          color="fuchsia"
        />
      </div>
      <ReadMore message={text} className="flex-col" textLimit={150} />
    </li>
  );
};
