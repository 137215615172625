import { components as SchemaComponent } from "../api/schema";
import { getMonth } from "date-fns";

import { STATUS } from "./statuses";

type _id = {
  _id: string;
};
export type LeadType = SchemaComponent["schemas"]["Lead"] & _id;

const compare = (a, b) => {
  if (a == b) return 0;

  return a > b ? 1 : -1;
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = ("0" + date.getMonth()).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};

const sortLeadsByDate = (a: LeadType, b: LeadType) => {
  const dateA = formatDate(new Date(a.created_at));
  const dateB = formatDate(new Date(b.created_at));

  return compare(dateB, dateA);
};

export const sortLeadsByStatus = (a: LeadType, b: LeadType) => {
  const statusA = a.status as STATUS;
  const statusB = b.status as STATUS;

  const statusOrder = Object.values(STATUS);

  return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
};

const sortLeadsByCandidateName = (a: LeadType, b: LeadType) => {
  const nameA = a.candidate.name;
  const nameB = b.candidate.name;

  return compare(nameA, nameB);
};

export const sortLeads = (a: LeadType, b: LeadType) => {
  const sortingFunctions = [
    sortLeadsByDate,
    sortLeadsByStatus,
    sortLeadsByCandidateName,
  ];

  let res = 0;

  while (sortingFunctions.length > 0 && res === 0) {
    const sortingFunction = sortingFunctions.shift();
    res = sortingFunction?.(a, b) || 0;
  }

  return res;
};

const month = getMonth(new Date());
const year = new Date().getFullYear();

export function getTimeIntervall(value) {
  let [from, to] = ["", ""];
  if (value === "Current period") {
    if (month < 6) {
      from = new Date(year, 0, 1).toISOString();
      to = new Date(year, 6, 1).toISOString();
    } else {
      from = new Date(year, 6, 1).toISOString();
      to = new Date(year, 11, 31).toISOString();
    }
    return { from, to };
  } else if (value === "Previous period") {
    if (month < 6) {
      from = new Date(year - 1, 6, 1).toISOString();
      to = new Date(year - 1, 11, 31).toISOString();
    } else {
      from = new Date(year, 0, 1).toISOString();
      to = new Date(year, 6, 1).toISOString();
    }
    return { from, to };
  } else {
    return {};
  }
}
