import {
  FC,
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import { useApi } from "./useApi";
import { components } from "../api/schema";

type DepartmentsType = components["schemas"]["Departments"];
type OfficesType = components["schemas"]["OfficeList"];
type TeamType = components["schemas"]["Teams"];
type Teams = components["schemas"]["TeamsList"];

interface Record {
  _id: string;
  name: string;
}

interface IteraStructureContext {
  officesIsLoading: boolean;
  departmentsIsLoading: boolean;
  teamsIsLoading: boolean;
  teams: Teams;
  setTeams: (value: Teams) => void;
  offices: OfficesType;
  setOffices: (value: OfficesType) => void;
  departments: DepartmentsType;
  setDepartments: (value: DepartmentsType) => void;
  pcs: Record[];
  getTeams: (teamId: string) => TeamType | undefined;
}

const pcs = [
  { _id: "PCS1", name: "PCS1" },
  { _id: "PCS2", name: "PCS2" },
  { _id: "PCS3", name: "PCS3" },
  { _id: "PCS4", name: "PCS4" },
  { _id: "PCS5", name: "PCS5" },
];

const noop = () => undefined;
const initialTeamsContext = {
  teams: [],
  offices: [],
  officesIsLoading: true,
  setOffices: noop,
  departments: [],
  departmentsIsLoading: true,
  setDepartments: noop,
  setTeams: noop,
  teamsIsLoading: true,
  pcs,
  getTeams: noop,
};

export const iteraStructureContext = createContext<IteraStructureContext>(
  initialTeamsContext as IteraStructureContext
);

export const IteraStructureProvider: FC<{
  children: ReactNode[];
}> = ({ children }) => {
  const { data: initialDepartments, isLoading: departmentsIsLoading } =
    useApi<DepartmentsType>("/department");
  const { data: initialOffices, isLoading: officesIsLoading } =
    useApi<OfficesType>("/offices");
  const { data: initialTeams, isLoading: teamsIsLoading } =
    useApi<Teams>("/teams");

  const [teams, setTeams] = useState<Teams>([]);
  const [offices, setOffices] = useState<OfficesType>([]);
  const [departments, setDepartments] =
    useState<DepartmentsType>(initialDepartments);

  useEffect(
    () => initialOffices && setOffices(initialOffices),
    [initialOffices]
  );
  useEffect(
    () => initialDepartments && setDepartments(initialDepartments),
    [initialDepartments]
  );
  useEffect(() => initialTeams && setTeams(initialTeams), [initialTeams]);

  const getTeams = (teamId: string) => {
    return teams.find((team) => team.short === teamId);
  };

  return (
    <iteraStructureContext.Provider
      value={{
        teams,
        getTeams,
        setTeams,
        teamsIsLoading,
        offices,
        officesIsLoading,
        setOffices,
        departments,
        departmentsIsLoading,
        setDepartments,
        pcs,
      }}
    >
      {children}
    </iteraStructureContext.Provider>
  );
};

export default () => useContext(iteraStructureContext);
