import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LeadLog } from "../../../components/recruiter/leadLog";
import { useApi } from "../../../helpers/useApi";
import { STATUS, getStatusText } from "../../../helpers/statuses";
import { Details } from "./Details";
import {
  getNameAbbreviation,
  getSelectStatuses,
  getSelectStatusOptions,
  getPreviousStatus,
  getInitialSteps,
  Step,
  FullTable,
} from "./helpers";
import {
  Button,
  ProgressBar,
  Snackbar,
  MessageType,
  Spinner,
  StatusIndicator,
} from "../../../components/common";
import { Notes } from "./Notes";
import { NoteType } from "./helpers";
import { updateLeadStatus } from "../../../api/lead";
import { LeadType } from "../../../helpers/sortLeads";

export const CandidateDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, error } = useApi<LeadType>(
    location ? `/leads/${location.pathname.replace("/candidate/", "")}` : "null"
  );
  const [lead, setLead] = useState<LeadType>(data);
  const [notes, setNotes] = useState<NoteType[]>([]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [message, setMessage] = useState("");
  const [changes, setChanges] = useState<{
    addStatus?: STATUS;
    deleteStatus?: STATUS;
  } | null>(null);

  const selectStatusOptions = useMemo(
    () => (lead ? getSelectStatusOptions(getSelectStatuses(lead)) : []),
    [lead]
  );

  useEffect(() => {
    if (data) {
      setLead(data);
      setNotes(data.notes ? data.notes : []);
    }
  }, [data]);

  const resetChangesAndSteps = () => {
    setChanges(null);
    setSteps(getInitialSteps(lead));
  };

  useEffect(() => {
    if (lead) resetChangesAndSteps();
  }, [lead]);

  const onSaveStatusHandler = async () => {
    if (!changes) return;
    const { addStatus, deleteStatus } = changes;
    const response = await updateLeadStatus(
      lead._id,
      addStatus || deleteStatus
    );
    if (response.error) {
      setMessage(response.message || response.error);
    } else {
      setLead(response);
    }
  };

  const setChangesAndSteps = (shouldDeleteStatus, status) => {
    setChanges(
      shouldDeleteStatus ? { deleteStatus: status } : { addStatus: status }
    );
    const initialSteps = getInitialSteps(lead);
    setSteps(
      initialSteps.map((initialStep) =>
        initialStep.status === status
          ? { ...initialStep, isActive: !initialStep.isActive }
          : initialStep
      )
    );
  };

  const getOnPointClickHandler = (step) => () => {
    const { isActive, status: clickedStatus } = step;
    const previousClick =
      changes && (changes.addStatus || changes.deleteStatus);
    const shoudResetToInitial = previousClick === clickedStatus;
    if (shoudResetToInitial) {
      resetChangesAndSteps();
    } else {
      const shouldDeleteStatus = isActive && clickedStatus === currentStatus;
      setChangesAndSteps(shouldDeleteStatus, clickedStatus);
    }
  };

  const onChangeSelectorHandler = (e) => {
    const { value } = e.currentTarget;
    const shoudResetToInitial = value === currentStatus;
    if (shoudResetToInitial) {
      return resetChangesAndSteps();
    }
    const previousStatus = getPreviousStatus(lead.status_updates);
    const shouldDeleteStatus = value === previousStatus;
    setChangesAndSteps(
      shouldDeleteStatus,
      shouldDeleteStatus ? currentStatus : value
    );
  };

  if (error) {
    return (
      <Snackbar
        type={MessageType.ERROR}
        message={error}
        onClose={() => navigate("/", { replace: true })}
      ></Snackbar>
    );
  } else if (!lead) {
    return <Spinner />;
  }

  const { status: currentStatus } = lead;
  const isLeadClosed = currentStatus === STATUS.CLOSED;
  let selectValue = currentStatus;
  if (changes) {
    selectValue =
      changes?.addStatus ||
      getPreviousStatus(lead.status_updates) ||
      // getPreviousStatus(when only one update - for example after deleting STATUS.ACCEPTED) returns null
      currentStatus;
  }

  return (
    <div className="py-8 px-8 bg-gray-200 h-auto min-h-screen">
      <div className="max-w-[1900px] flex flex-wrap gap-4">
        <FullTable className="flex-1 flex !p-6">
          <StatusIndicator
            className="min-w-[70px] h-[70px] mr-6 hidden lg:flex"
            content={getNameAbbreviation(lead.candidate.name)}
            disabled={isLeadClosed}
          />
          <div className="w-[900px]">
            <h3 className="text-h3 mb-12">{lead.candidate.name}</h3>
            <div className="">
              <h4 className="text-h4 mb-6">
                Status <span>({currentStatus})</span>
              </h4>
              <ProgressBar
                className="hidden sm:flex mb-6"
                processEnded={currentStatus === STATUS.CLOSED}
                steps={steps}
                getOnPointClickHandler={getOnPointClickHandler}
              />
              <div className="flex flex-col justify-between items-start sm:flex-row sm:items-end">
                <div className="w-80">
                  <div className="mb-2">Change status</div>
                  <select
                    className=" border-gray-300 mr-4 w-full"
                    onChange={onChangeSelectorHandler}
                    value={selectValue}
                  >
                    {selectStatusOptions}
                  </select>
                </div>
                <Button
                  name={
                    (changes?.deleteStatus &&
                      `Delete ${getStatusText(changes.deleteStatus)}`) ||
                    (changes?.addStatus &&
                      `Save ${getStatusText(changes.addStatus)}`) ||
                    "Save"
                  }
                  disabled={!changes}
                  onClick={onSaveStatusHandler}
                  color="fuchsia"
                />
              </div>
            </div>
            <hr className="my-10" />
            <Details lead={lead} setMessage={setMessage} setLead={setLead} />
            <hr className="my-10" />
            <Notes
              leadId={lead._id}
              notes={notes}
              setMessage={setMessage}
              setNotes={setNotes}
            />
          </div>
        </FullTable>
        <FullTable className="w-full lg:w-1/4 ">
          <LeadLog statusUpdates={lead.status_updates} />
        </FullTable>
      </div>
      <Snackbar
        type={MessageType.ERROR}
        message={message}
        onClose={() => setMessage("")}
      ></Snackbar>
    </div>
  );
};
