import { FC } from "react";

export const getStatusColour = (buttonColor: string) => {
  let statusColor = "";
  switch (buttonColor) {
    case "fuchsia":
      statusColor =
        "group-hover:fill-fuchsia group-focus:fill-fuchsia group-disabled:fill-lavender";
      break;
    case "jade":
      statusColor =
        "group-hover:fill-black group-focus:fill-black group-disabled:fill-grass";
      break;
    case "sea":
      statusColor =
        "group-hover:fill-sea group-focus:fill-sea group-disabled:fill-sky";
      break;
    case "gold":
      statusColor =
        "group-hover:fill-black group-focus:fill-black group-disabled:fill-lemon";
      break;
    case "lobster":
      statusColor =
        "group-hover:fill-lobster group-focus:fill-lobster group-disabled:fill-peach";
      break;
    default:
      statusColor = "group-hover:fill-fuchsia";
  }
  return statusColor;
};

export const Arrow: FC<{
  buttonColor: string;
}> = ({ buttonColor }) => {
  return (
    <svg
      width="20"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="my-auto"
    >
      <path
        d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
        fill={
          buttonColor === "gold" || buttonColor === "jade" ? "black" : "white"
        }
        className={getStatusColour(buttonColor)}
      />
    </svg>
  );
};
