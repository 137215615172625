import { FC } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";

interface NewLeadInputProps {
  className?: string;
  errorMessage: string | undefined | FieldError;
  inputId: string;
  title: string;
  inputPlaceholder?: string;
  inputType: string;
  defaultValue?: string;
  register: () => any;
}

interface NewLeadSelectProps {
  className?: string;
  errorMessage: string | undefined;
  inputId: string;
  title: string;
  options: { _id?: string; short?: string; name: string }[];
  defaultValue?: string;
  register: () => any;
}

export const NewLeadInput: FC<NewLeadInputProps> = (props) => {
  const {
    className,
    errorMessage,
    inputId,
    title,
    inputPlaceholder,
    inputType,
    defaultValue,
    register,
  } = props;
  return (
    <>
      <label htmlFor={inputId}>{title}</label>
      <input
        type={inputType}
        id={inputId}
        defaultValue={defaultValue}
        placeholder={inputPlaceholder}
        aria-invalid={errorMessage ? "true" : false}
        className={classNames(
          " w-full border-white placeholder:italic placeholder:text-gray-300 " +
            className,
          errorMessage
            ? "border-lobster border-b-lobster"
            : "border-transparent"
        )}
        autoComplete="off"
        {...register()}
      />
      <span
        role="alert"
        id={`${inputId}-alert`}
        className="mb-6 mt-1 text-lobsterNight"
      >
        {errorMessage}
      </span>
    </>
  );
};

export const NewLeadSelect: FC<NewLeadSelectProps> = (props) => {
  const { errorMessage, inputId, title, options, defaultValue, register } =
    props;
  return (
    <>
      <label htmlFor={inputId}>{title}</label>
      <select
        id={inputId}
        defaultValue={defaultValue}
        aria-invalid={errorMessage ? "true" : false}
        className={classNames(
          " w-full placeholder:italic placeholder:text-gray-300 border-0 border-b-1 border-black",
          errorMessage ? "border-lobster" : "border-transparent"
        )}
        {...register()}
      >
        <option></option>
        {options?.map((e, index) => {
          return (
            <option key={index} value={e._id}>
              {e.name}
            </option>
          );
        })}
      </select>
      <span
        role="alert"
        id={`${inputId}-alert`}
        className="mb-6 mt-1 text-lobsterNight"
      >
        {errorMessage}
      </span>
    </>
  );
};
