import { FC, useState } from "react";
import classNames from "classnames";

interface DetailsProps {
  message: string;
  textLimit?: number;
  className?: string;
}

export const ReadMore: FC<DetailsProps> = ({
  message,
  textLimit = 180,
  className,
}) => {
  if (!message) return null;
  const [isFullTextShown, setIsFullTextShown] = useState(false);
  const isTextLong = message.length > textLimit;
  const text =
    isTextLong && !isFullTextShown
      ? message.slice(0, textLimit) + "... "
      : message;

  return (
    <div
      className={classNames(
        "border-2 p-4 border-gray-300 flex justify-between w-full",
        className
      )}
    >
      <p className="flex-1 text-justify">{text}</p>
      {isTextLong && (
        <button
          className="text-sea self-end"
          onClick={() => setIsFullTextShown(!isFullTextShown)}
        >
          {isFullTextShown ? "Shorten text" : "Read more"}
        </button>
      )}
    </div>
  );
};
