import { FC, useState } from "react";
import { TwoColumnLayout } from "../../components/layout/TwoColumnLayout";
import { Select } from "../../components/employee/howToTipAbout/select/Select";
import { Link } from "react-router-dom";
import { Path } from "../../routes";
import { PlusTextButton } from "../../components/common/PlusTextButton";

export const WhoToTipAbout: FC = () => {
  const selectOptions = {
    TECHNOLOGY: {
      label: "Technology",
      content:
        "Technology consists of developers, architects and QA people. They want people who like to work in interdisciplinary teams. Developers are in high demand and they are looking for frontend, backend and fullstack developers in all the most used frameworks. If in doubt about the candidate's skill level, ask your manager.",
    },
  };
  const [selectContent, setSelectContent] = useState(
    selectOptions.TECHNOLOGY.content
  );
  const handleSelect = (value: string) => {
    setSelectContent(value);
  };
  return (
    <TwoColumnLayout
      headlines={{
        left: "Who is Itera looking for?",
        right: "Tips and tricks for finding a candidate:",
      }}
      left={
        <>
          <Select
            title="Department"
            inputId="department"
            defaultValue={selectContent}
            options={selectOptions}
            onChange={handleSelect}
          />
          <p className="mt-8 mb-14 leading-8">{selectContent}</p>
          <Link to={Path.NEWLEAD}>
            <PlusTextButton color="fuchsia" name="Submit new lead" />
          </Link>
        </>
      }
      right={
        <>
          <ul className="list-disc list-outside children:mb-8 children:ml-6 children:mr-0 leading-8">
            <li>
              Invite an old acquaintance to one of our subject matter nights and
              let them see for themselves what Itera is like.
            </li>
            <li>
              You have to know someone personally to submit a lead about them,
              but we don't have to tell them that you submitted the lead!
            </li>
            <li>
              The candidate do not have to be actively looking for a job, you
              never know when a call from Itera might come in just the right
              moment.
            </li>
          </ul>
          <div className="py-8 px-10 bg-lavenderBright relative flex flex-col leading-8 max-w-md">
            <p>
              We are always looking for good people, so if you know someone
              talented we want to hear about it!
            </p>
          </div>
        </>
      }
    />
  );
};
