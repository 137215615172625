import classNames from "classnames";
import { FC } from "react";

export const getButtonColor = (buttonColor: string) => {
  let statusColor = "";
  switch (buttonColor) {
    case "fuchsia":
      statusColor =
        "border-fuchsia bg-fuchsia hover:text-fuchsia focus:text-fuchsia focus:bg-lavenderBright disabled:text-lavender disabled:bg-lavenderBright disabled:border-lavender";
      break;
    case "jade":
      statusColor =
        "border-jade bg-jade hover:text-jade focus:text-jade focus:bg-grassBright disabled:text-grass disabled:bg-grassBright disabled:border-grass";
      break;
    case "sea":
      statusColor =
        "border-sea bg-sea hover:text-sea focus:text-sea focus:bg-skyBright disabled:text-sky disabled:bg-skyBright disabled:border-sky";
      break;
    case "gold":
      statusColor =
        "border-gold bg-gold hover:text-gold focus:text-gold focus:bg-lemonBright disabled:text-lemon disabled:bg-lemonBright disabled:border-lemon";
      break;
    case "lobster":
      statusColor =
        "border-lobster bg-lobster hover:text-lobster focus:text-lobster focus:bg-peachBright disabled:text-peach disabled:bg-peachBright disabled:border-peach";
      break;
    default:
      statusColor = "hover:text-fuchsia";
  }
  return statusColor;
};

export const Button: FC<{
  onClick?: () => void;
  className?: string;
  color?: string;
  name?: string;
  disabled?: boolean;
  id?: string;
  type?: "submit" | "button" | "reset";
}> = ({ className = "", name, type = "button", color = "", ...otherProps }) => (
  <button
    {...otherProps}
    className={classNames(
      "font-sans px-6 py-2.5  border-2 font-semibold  text-white cursor-pointer",
      " hover:bg-white ease-out duration-200",
      className + getButtonColor(color)
    )}
    type={type}
  >
    {name}
  </button>
);
