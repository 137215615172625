import { FC } from "react";

export const Branding: FC = () => {
  return (
    <div className="flex flex-row gap-4 align-middle content-center	">
      <svg
        className="my-auto"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0V22H21.2667V0H0ZM16.8667 6.6H12.4667V15.4H16.8667V18.3333H4.4V15.4H8.8V6.6H4.4V3.66667H16.8667V6.6Z"
          fill="#AF1DFF"
        />
      </svg>
      <p className="text-ivory font-neue text-[20px] mt-auto">MAD Leads</p>
    </div>
  );
};
