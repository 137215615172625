import { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../images/icons/ArrowRight.svg";
import gif1 from "../../../../images/gifs/celebration/gifs1.gif";
import gif2 from "../../../../images/gifs/celebration/gifs2.gif";
import gif3 from "../../../../images/gifs/celebration/gifs3.gif";
import gif4 from "../../../../images/gifs/celebration/gifs4.gif";
import { Path } from "../../../../routes";
export const FormComplete: FC = () => {
  return (
    <>
      <div className="relative">
        <p className="text-3xl my-2">
          We are one step closer to having a new colleague in Itera!
        </p>
        <div className="flex flex-row pt-2">
          <div className="flex-[0_0_50%] h-auto relative">
            <img
              src={gif1}
              className="h-full w-full shadow-sm"
              alt="Celebration animation"
            />
            <img
              src={gif4}
              alt="Celebration animation"
              className="absolute bottom-0 left-0 w-[40%] h-1/2"
              style={{
                clipPath:
                  "polygon(0 0, 50% 0, 50% 25%, 100% 25%, 100% 100%, 0 100%)",
              }}
            />
          </div>
          <div className="flex-[0_0_50%] h-auto relative">
            <img
              src={gif2}
              className="h-full w-full"
              alt="Celebration animation"
            />
            <img
              src={gif3}
              alt="Celebration animation"
              className="absolute top-0 right-0 w-[50%] h-full	"
              style={{
                clipPath:
                  "polygon(0 80%, 30% 80%, 30% 0, 100% 0, 100% 100%, 0 100%)",
              }}
            />
          </div>
        </div>
      </div>
      <h2 className="text-3xl mt-4 mb-2 hidden">30000 points received</h2>
      <div className="flex flex-row place-content-center py-16">
        <Link
          to={Path.MYLEADS}
          className="flex flex-row py-[18px] px-[24px] bg-fuchsia text-white  max-w-[217px]"
        >
          <ArrowRight className="my-auto mr-2 " />
          Your leads
        </Link>
      </div>
    </>
  );
};
