import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { components } from "../../api/schema";
import { NewLeadInput } from "../employee/leads";
import { PlusTextButton } from "../common/PlusTextButton";
import { Spinner } from "../common";
import StructureList from "./StructureList";
import { createTeam } from "../../api/teams";

type TeamType = components["schemas"]["Teams"];

type Inputs = {
  name: string;
  short: string;
};

export const RegisterNewTeam = ({ teamsIsLoading, teams, setTeams }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const [formComplete, setFormComplete] = useState(false);
  const [inputTeam, setInputTeam] = useState("");

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setFormComplete(false);
      }, 1500);
    };
  }, [formComplete]);

  const onSubmit = (data) => {
    const teamData: TeamType = {
      _id: data._id,
      name: data.name,
      short: data.short,
    };

    createTeam(teamData)
      .then((res) => {
        if (res.error) {
          alert(res.message);
          return;
        }
        setFormComplete(true);
        setInputTeam(teamData.name);
        const newTeams = [...teams];
        newTeams.push(res);
        setTeams(newTeams);
        reset();
      })
      .catch((err) => console.error(err));
    return false;
  };

  return (
    <div className="mt-4">
      <form
        className="px-6 lg:px-0 w-full "
        autoComplete="off"
        id="new-team"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="block">
          <NewLeadInput
            className=" border-b-black w-4/12 block "
            errorMessage={errors.short?.message}
            inputId="short"
            inputPlaceholder="dev-1"
            inputType="text"
            title="Team shorthand name:"
            register={() =>
              register("short", {
                required: "Short name is required",
                pattern: {
                  value: /^\S*$/,
                  message: "Can not contain spaces",
                },
              })
            }
          />
        </div>
        <div className="block mt-6">
          <NewLeadInput
            className=" border-b-black w-4/12 block "
            errorMessage={errors.name?.message}
            inputId="name"
            inputPlaceholder="Cloud and application services"
            inputType="text"
            title="Team full name:"
            register={() =>
              register("name", {
                required: "Full name is required",
              })
            }
          />
        </div>
        <div className="block py-4">
          <PlusTextButton color="lobster" name="Add team!" />
        </div>
        {formComplete ? "Team '" + inputTeam + "' added!" : ""}
      </form>
      {teamsIsLoading ? (
        <Spinner />
      ) : (
        <StructureList structure={teams} title="teams" type="team" />
      )}
    </div>
  );
};
