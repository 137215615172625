import { FC } from "react";
import classNames from "classnames";

export interface ProgressPoint {
  status?: string;
  text: string;
  isActive?: boolean;
  isInteractive?: boolean;
}
interface ProgressBarProps {
  steps: ProgressPoint[];
  processEnded: boolean;
  className?: string;
  getOnPointClickHandler?: (step: ProgressPoint) => () => void;
}

export const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { steps, processEnded, className, getOnPointClickHandler } = props;

  return (
    <div className={classNames("flex flex-row w-full relative", className)}>
      {steps.map((step, i) => {
        return (
          <div
            key={i}
            className={classNames(
              "flex flex-col flex-1",
              { "items-start": i === 0 },
              { "items-center text-center": i > 0 && i < steps.length - 1 },
              { "items-end text-right": i === steps.length - 1 }
            )}
            style={
              !i || i === steps.length - 1
                ? { maxWidth: `${50 / (steps.length - 1)}%` }
                : {}
            }
            onClick={
              (step.isInteractive &&
                getOnPointClickHandler &&
                getOnPointClickHandler(step)) ||
              undefined
            }
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              className={classNames(
                "z-10",
                { "bg-ivory": processEnded },
                { "bg-lavenderBright": !processEnded },
                {
                  "cursor-pointer": step.isInteractive,
                }
              )}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1"
                y="1"
                width="22"
                height="22"
                stroke={processEnded ? "#4F4F4F" : "#AF1DFF"}
                strokeWidth="2"
              />
              <path
                d="M4 4H20V20H4V4Z"
                fill={processEnded === true ? "#4F4F4F" : "#AF1DFF"}
                visibility={step.isActive ? "visible" : "hidden"}
              />
            </svg>
            <p className="mt-6">{step.text}</p>
          </div>
        );
      })}
      {!processEnded && (
        <span
          className="h-1 bg-fuchsia absolute top-[12px] -translate-y-1/2"
          style={{ width: "calc(100% - 1px)" }}
        />
      )}

      {processEnded && (
        <span
          className="h-1 bg-gray-500 absolute top-[12px] -translate-y-1/2"
          style={{ width: "calc(100% - 1px)" }}
        />
      )}
    </div>
  );
};
