import { useState } from "react";
import { ReactComponent as SearchIcon } from "./search_icon.svg";

export const SearchByFilter = ({ searchItem, setFilter, placeholder }) => {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter(searchItem, value); // searchItem => "candidate.name"
    e.target.value === "" ? setFilterInput("") : setFilterInput(value);
  };

  return (
    <span className="float-right ">
      <div className="pb-3 inline-flex">
        <input
          className="min-w-[400px] px-3 py-2 font-normal bg-gray-100"
          placeholder={`${placeholder}`}
          value={filterInput}
          onChange={handleFilterChange}
        />
        <span className="p-3 bg-gray-100">
          <SearchIcon />
        </span>
      </div>
    </span>
  );
};
