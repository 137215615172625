import { FC } from "react";
import { BoxColor } from "./PointBox";
import classNames from "classnames";

interface Props {
  number: number;
  symbol?: string;
  label: string;
  color?: BoxColor.Lavender | BoxColor.Sky;
}

export const StatsBox: FC<Props> = ({
  number,
  symbol,
  label,
  color = BoxColor.Lavender,
}) => {
  const statsBoxStyle = classNames(
    color,
    "border rounded-lg border-black sm:flex-start sm:flex-none sm:max-w-auto max-w-[47%] flex-1 px-[24px] py-[6px] relative block text-center justify-center items-center min-h-[88px] min-w-[160px]"
  );

  return (
    <div className={statsBoxStyle}>
      <h2 className="text-h2">
        {String(number)}
        {symbol}
      </h2>
      <p>{label}</p>
    </div>
  );
};
