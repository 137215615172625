export enum STATUS {
  NEW = "new",
  ACCEPTED = "accepted",
  CONTACTED = "contacted",
  INTERVIEW1 = "1st_interview",
  INTERVIEW2 = "2nd_interview",
  INTERVIEW3 = "3rd_interview",
  OFFER = "offer",
  HIRED = "hired",
  CLOSED = "closed",
}

const statusTexts = {
  [STATUS.NEW]: "Lead submitted",
  [STATUS.ACCEPTED]: "Lead accepted",
  [STATUS.CONTACTED]: "Lead contacted",
  [STATUS.INTERVIEW1]: "1st Interview",
  [STATUS.INTERVIEW2]: "2nd Interview",
  [STATUS.INTERVIEW3]: "3rd Interview",
  [STATUS.OFFER]: "Offer",
  [STATUS.CLOSED]: "Closed",
  [STATUS.HIRED]: "Hired",
};

export const getStatusText = (status: string) => statusTexts[status] || status;
