import { FC, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  NewLeadInput,
  NewLeadSelect,
} from "../../../components/employee/leads";
import { PCSType } from "../../../components/employee/leads/registerNewLead/RegisterNewLead";
import { useApi } from "../../../helpers/useApi";
import useAuth from "../../../helpers/useTestRecruiter";
import { Button } from "../../../components/common";
import { CloseButton } from "../../../components/common/CloseButton";
import { LeadType } from "../../../helpers/sortLeads";
import { iteraStructureContext } from "../../../helpers/useIteraStructureContext";
import { UserType } from "../../../components/employee/dashboard/stats/PointsOverview";

export type ModalData = {
  name: string;
  linkedIn: string;
  email: string;
  mobile: number;
  location: string;
  department: string;
  pcs: PCSType;
  on_behalf_of: string;
  responsible?: string;
  created_by: string;
};

interface LeadModalProps {
  lead: LeadType;
  onClose: () => void;
  onSave: (data) => void;
  users: any;
  recruiters: any;
}

export const LeadModal: FC<LeadModalProps> = ({
  lead,
  onClose,
  onSave,
  users,
  recruiters,
}) => {
  const [, ...toggleModeAndAccount] = useAuth();
  const account = toggleModeAndAccount[1];

  const { data, error } = useApi<UserType>(
    `/users/${account?.homeAccountId.split(".")[0]}`
  );

  const recruiterOptions = recruiters.map((user) => {
    return { _id: user.external_id.replace(/-/g, ""), name: user.name };
  });

  const isAdmin = data && !error && data.roles?.includes("admin");

  const { offices, departments, pcs } = useContext(iteraStructureContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ModalData>();

  const onSubmit: SubmitHandler<ModalData> = ({
    name,
    email,
    mobile,
    linkedIn,
    ...otherData
  }) => {
    const candidate = {
      name,
      email,
      phone: String(mobile),
      linkedin_url: linkedIn,
    };
    onSave({ candidate, ...otherData });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 p-6 rounded-lg shadow-lg relative flex flex-col w-full bg-lavenderBright outline-none focus:outline-none">
            <h3 className="text-h3 px-5">Details</h3>
            <div className="flex items-start justify-between p-5 rounded-t">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap w-full gap-0 md:gap-8">
                  <div className="flex flex-col flex-[0_0_100%] lg:flex-1">
                    <NewLeadInput
                      errorMessage={errors.name?.message}
                      inputId="name"
                      defaultValue={lead.candidate.name}
                      inputType="text"
                      title="Name"
                      register={() =>
                        register("name", {
                          required: "Complete name is required",
                        })
                      }
                    />
                    <NewLeadInput
                      errorMessage={errors.linkedIn?.message}
                      inputId="linkedin"
                      defaultValue={lead.candidate.linkedin_url}
                      inputType="text"
                      title="LinkedIn URL"
                      register={() =>
                        register("linkedIn", {
                          pattern: {
                            value: /linkedin.com/,
                            message: "URL is not a valid LinkedIn url",
                          },
                        })
                      }
                    />
                    <NewLeadInput
                      errorMessage={errors.email?.message}
                      inputId="email"
                      defaultValue={lead.candidate.email}
                      inputType="email"
                      title="E-mail"
                      register={() =>
                        register("email", {
                          pattern: {
                            value:
                              /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                            message: "The input is not a valid e-mail",
                          },
                        })
                      }
                    />
                    <NewLeadInput
                      errorMessage={errors.mobile?.message}
                      inputId="mobile"
                      inputType="tel"
                      defaultValue={lead.candidate.phone}
                      title="Mobile"
                      register={() =>
                        register("mobile", {
                          pattern: {
                            value: /^((00)|(\+))\d{10,15}$/,
                            message: "The input is not a valid phone number",
                          },
                        })
                      }
                    />
                    {isAdmin && (
                      <NewLeadInput
                        errorMessage={errors.on_behalf_of?.message}
                        inputId="on_behalf_of"
                        defaultValue={lead.on_behalf_of}
                        inputType="text"
                        title="Lead submitted on behalf of"
                        register={() =>
                          register("on_behalf_of", {
                            required: false,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="flex flex-col flex-[0_0_100%] lg:flex-1">
                    <NewLeadSelect
                      errorMessage={errors.location?.message}
                      title="Office"
                      inputId="location"
                      defaultValue={lead.location?._id}
                      register={() =>
                        register("location", {
                          required: "Office is required",
                        })
                      }
                      options={offices}
                    />
                    <NewLeadSelect
                      errorMessage={errors.department?.message}
                      title="Department"
                      inputId="department"
                      defaultValue={lead.department?._id}
                      register={() =>
                        register("department", {
                          required: "Department is required",
                        })
                      }
                      options={departments}
                    />
                    <NewLeadSelect
                      errorMessage={errors.pcs?.message}
                      title="PCS Level"
                      inputId="pcs"
                      defaultValue={lead.pcs}
                      register={() =>
                        register("pcs", {
                          required: "PCS is required",
                        })
                      }
                      options={pcs}
                    />
                    {isAdmin && users && (
                      <NewLeadSelect
                        errorMessage={errors.created_by?.message}
                        title="Lead submitted by"
                        inputId="created_by"
                        defaultValue={lead.created_by?._id}
                        register={() =>
                          register("created_by", {
                            required: "Lead submitter is required",
                          })
                        }
                        options={users}
                      />
                    )}
                    {isAdmin && users && (
                      <NewLeadSelect
                        errorMessage={errors.responsible?.message}
                        title="Responsible for lead"
                        inputId="responsible"
                        defaultValue={lead.responsible?.external_id.replace(
                          /-/g,
                          ""
                        )}
                        register={() =>
                          register("responsible", {
                            required: false,
                          })
                        }
                        options={recruiterOptions}
                      />
                    )}
                  </div>
                  <div className="flex-[0_0_100%]">
                    <Button
                      id="submit"
                      type="submit"
                      name="Save"
                      color="fuchsia"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*Close button*/}
            <CloseButton
              className="absolute top-4 right-4 ml-auto"
              onClick={onClose}
              backgroundColor="bg-lavenderBright"
            />
          </div>
        </div>
      </div>
      {/*background*/}
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
