import { STATUS, getStatusText } from "../../../helpers/statuses";
import classNames from "classnames";
import { FC } from "react";
import { components as SchemaComponent } from "../../../api/schema";

export const statuses = (currentStatus: string) =>
  ({
    [STATUS.NEW]: ["accepted", "closed"],
    [STATUS.ACCEPTED]: ["contacted", "closed"],
    [STATUS.CONTACTED]: ["1st_interview", "closed"],
    [STATUS.INTERVIEW1]: ["2nd_interview", "offer", "closed"],
    [STATUS.INTERVIEW2]: ["3rd_interview", "offer", "closed"],
    [STATUS.INTERVIEW3]: ["offer", "closed"],
    [STATUS.OFFER]: ["hired", "closed"],
  }[currentStatus] || []);

export const getNameAbbreviation = (name) =>
  name
    .split(" ")
    .map((namePart) => (namePart[0] || "").toUpperCase())
    .slice(0, 3)
    .join("");

export const getPreviousStatus = (statusUpdates) => {
  const { length } = statusUpdates;
  return length > 1 ? statusUpdates[length - 2].status : null;
};

export const getChangeableStatuses = (currentStatus) =>
  currentStatus ? [currentStatus, ...statuses(currentStatus)] : [];

export const getSelectStatuses = (lead) => {
  const previousStatus = getPreviousStatus(lead.status_updates ?? []);
  const changeableStatuses = getChangeableStatuses(lead.status);
  return previousStatus
    ? [previousStatus, ...changeableStatuses]
    : changeableStatuses;
};

export const getSelectStatusOptions = (statuses) =>
  statuses.map((status) => (
    <option key={status} value={status}>
      {getStatusText(status)}
    </option>
  ));

export const getAllSteps = (currentStatus) => {
  type Step = {
    [key: string]: string;
  };

  const steps: Step[] = [
    { [STATUS.NEW]: "Lead submitted" },
    { [STATUS.ACCEPTED]: "Lead accepted" },
    { [STATUS.CONTACTED]: "Lead contacted" },
    { [STATUS.INTERVIEW1]: "1st Interview" },
    { [STATUS.INTERVIEW2]: "2nd Interview" },
    { [STATUS.INTERVIEW3]: "3rd Interview" },
    { [STATUS.OFFER]: "Offer" },
  ];

  if (currentStatus === STATUS.OFFER) {
    steps.push({ [STATUS.HIRED]: "Hired" });
    steps.push({ [STATUS.CLOSED]: "Closed" });
  }

  if (currentStatus === STATUS.HIRED) {
    steps.push({ [STATUS.HIRED]: "Hired" });
  }

  if (currentStatus === STATUS.CLOSED) {
    steps.push({ [STATUS.CLOSED]: "Closed" });
  }

  return steps;
};

export const getInitialSteps = (data) => {
  const currentStatus = data.status;
  const activeStatuses = data.status_updates.map((el) => el.status);
  const changeableStatuses = getChangeableStatuses(currentStatus);
  return getAllSteps(currentStatus)
    .map(Object.entries)
    .map(([[status, text]]) => ({
      status,
      text,
      isActive: activeStatuses.includes(status),
      isInteractive:
        status !== STATUS.NEW && changeableStatuses.includes(status),
    }));
};

export interface Step {
  status: string;
  text: string;
  isActive: boolean;
  isInteractive: boolean;
}

interface FullTableProps {
  className?: string;
}
export const FullTable: FC<FullTableProps> = ({ children, className }) => {
  return (
    <div className={classNames("bg-white shadow-md py-5 px-4", className)}>
      {children}
    </div>
  );
};

export type NoteType = SchemaComponent["schemas"]["Note"];
