export interface IteraTeam {
  _id: string;
  short: string;
  name: string;
}

export const teams: IteraTeam[] = [
  {
    _id: "1",
    short: "cic",
    name: "Cicero",
  },
  {
    _id: "2",
    short: "exp-ser",
    name: "Service Design",
  },
  {
    _id: "3",
    short: "exp-bus",
    name: "Business Design",
  },
  {
    _id: "4",
    short: "exp-dig",
    name: "Digital Design",
  },
  {
    _id: "5",
    short: "exp-int",
    name: "Interaction Design",
  },
  {
    _id: "6",
    short: "exp-bra",
    name: "Brand Innovation",
  },
  {
    _id: "7",
    short: "dev-arc",
    name: "Architecture",
  },
  {
    _id: "8",
    short: "dev-qa",
    name: "Quality Assurance",
  },
  {
    _id: "9",
    short: "dev-1",
    name: "Development Team 1",
  },
  {
    _id: "10",
    short: "dev-2",
    name: "Development Team 2",
  },
  {
    _id: "11",
    short: "dev-3",
    name: "Development Team 3",
  },
  {
    _id: "12",
    short: "dev-4",
    name: "Development Team 4",
  },
  {
    _id: "13",
    short: "dev-5",
    name: "Development Team 5",
  },
  {
    _id: "14",
    short: "dev-6",
    name: "Development Team 6",
  },
  {
    _id: "15",
    short: "dev-7",
    name: "Development Team 7",
  },
  {
    _id: "16",
    short: "adv-dbd",
    name: "Digital Business and Delivery",
  },
  {
    _id: "17",
    short: "adv-tech",
    name: "Technology Strategy",
  },
  {
    _id: "18",
    short: "adv-data",
    name: "Data Transformation",
  },
  {
    _id: "19",
    short: "adv-ai",
    name: "AI & Analytics",
  },
  {
    _id: "20",
    short: "bgr-tech",
    name: "Bergen Technology",
  },
  {
    _id: "21",
    short: "bgr-prj",
    name: "Team Bergen Agile Delivery",
  },
  {
    _id: "22",
    short: "frk-cir",
    name: "Team Cirrus",
  },
  {
    _id: "23",
    short: "frk-cumu",
    name: "Team Cumulus",
  },
  {
    _id: "24",
    short: "cas-aaa",
    name: "Architecture,​ Agile & Automation",
  },
  {
    _id: "25",
    short: "cas-mig",
    name: "Migration Team",
  },
  {
    _id: "26",
    short: "cas-pro",
    name: "Process & Security",
  },
  {
    _id: "27",
    short: "cas-cce",
    name: "Cloud Center of Excellence",
  },
  {
    _id: "28",
    short: "cas-alm",
    name: "Application Lifecycle Management",
  },
  {
    _id: "29",
    short: "cas-work",
    name: "Managed Workloads",
  },
  {
    _id: "30",
    short: "itera-sale",
    name: "Sales",
  },
  {
    _id: "31",
    short: "itera-asa",
    name: "Itera ASA",
  },
];

const rootAvatarUrl = location.protocol + "//" + location.host + "/avatars/";

const teamsAbbreviation = {
  cic: "cic",
  "exp-ser": "ex",
  "exp-bus": "ex",
  "exp-dig": "ex",
  "exp-int": "ex",
  "exp-bra": "ex",
  "dev-1": "dev",
  "dev-2": "dev",
  "dev-3": "dev",
  "dev-4": "dev",
  "dev-5": "dev",
  "dev-6": "dev",
  "dev-7": "dev",
  "dev-arc": "a",
  "dev-qa": "qa",
  "adv-dbd": "da",
  "adv-data": "da",
  "adv-tech": "da",
  "adv-ai": "da",
  "frk-cir": "fr",
  "frk-cumu": "fr",
  "bgr-tech": "be",
  "bgr-prj": "be",
  "cas-aaa": "cas",
  "cas-mig": "cas",
  "cas-pro": "cas",
  "cas-cce": "cas",
  "cas-alm": "cas",
  "cas-work": "cas",
  "itera-asa": "itera",
  "itera-sale": "itera",
};

export const getTeamAvatar = (team: string) => {
  const thisTeamAbbreviation = teamsAbbreviation[team];
  return thisTeamAbbreviation
    ? `${rootAvatarUrl}avatar-team-${thisTeamAbbreviation}.png`
    : "";
};
