import React from "react";
import { TwoColumnLayout } from "../../components/layout/TwoColumnLayout";

export const WhyShouldISubmit: React.FC = () => {
  return (
    <TwoColumnLayout
      headlines={{ left: "Why should I submit a lead?", right: " " }}
      left={
        <ul className="list-disc">
          <li className="leading-loose mt-20 mb-6">
            When you submit leads, even if you are not sure that the candidate
            will get hired or they even want to change jobs, Itera's chances to
            gain new talent increases.
          </li>

          <li className="leading-loose mb-6">
            Referring someone you know is the best way to influence who your new
            colleague will be. In Itera we grow organic, meaning we are meeting
            with each candidate to ensure the person is a right fit for us and
            our culture.
          </li>

          <li className="leading-loose">
            Submit your lead and choose your own colleague, this is a great
            opportunity for you to impact both our growth and the practice area
            that you work in!
          </li>
        </ul>
      }
      right={
        <div className="h-100 relative">
          <a
            href="https://iteracloud.sharepoint.com/sites/framework/Itera%20Framework/Referral%20Program.aspx"
            target="_blank"
            rel="noreferrer"
            className="text-fuchsia font-passionate sm:text-h3 text-5xl top-44 absolute underline"
          >
            Read the rules here
          </a>
        </div>
      }
    />
  );
};
