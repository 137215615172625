import { useMsal } from "@azure/msal-react";
import { FC, useEffect, useState } from "react";
import classNames from "classnames";

import { useApi } from "../../../../helpers/useApi";
import { Spinner } from "../../../common/Spinner";
import { PointsOverviewComponent } from "./PointsOverviewComponent";
import getQueryString from "../../../../helpers/getQueryString";
import { getTimeIntervall } from "../../../../helpers/sortLeads";

import { components as APIcomponents } from "../../../../api/schema";

export type UserType = APIcomponents["schemas"]["User"];
type PointsItemType = APIcomponents["schemas"]["PointsItem"];
type TeamStatisticsType = APIcomponents["schemas"]["TeamStatistics"];

export const PointsOverview: FC<{
  className?: string;
  timeValue: string;
}> = ({ className, timeValue }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].homeAccountId.split(".")[0];
  const [queryOptions, setQueryOptions] = useState({});

  const { data: user, isLoading: isUserLoading } = useApi<UserType>(
    `/users/${userId}`
  );
  const { data: userPoints, isLoading: isUsersPointsLoading } =
    useApi<PointsItemType>(
      "/points/individuals/own" + getQueryString(queryOptions)
    );
  const { data: teamPoints, isLoading: isTeamStatisticsLoading } =
    useApi<TeamStatisticsType>(() =>
      user.team
        ? `/statistics/team/${user.team}` + getQueryString(queryOptions)
        : false
    );

  function selectHandler(value) {
    setQueryOptions(getTimeIntervall(value));
  }

  const isLoading = isUserLoading || isUsersPointsLoading;

  useEffect(() => {
    selectHandler(timeValue);
  }, [timeValue]);

  if (isLoading) return <Spinner />;

  return (
    <div
      className={classNames(
        "flex items-center space-between gap-x-6",
        className
      )}
    >
      <PointsOverviewComponent
        points={userPoints?.points ? userPoints.points : 0}
        data={userPoints}
        header="Your points"
      />
      {user?.team && !isTeamStatisticsLoading ? (
        <PointsOverviewComponent
          points={teamPoints ? teamPoints.points : 0}
          data={teamPoints}
          header="Team points"
        />
      ) : (
        <></>
      )}
    </div>
  );
};
