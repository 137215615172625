import { FC } from "react";
import classNames from "classnames";

interface Props {
  number?: number;
  symbol?: string;
  label?: string;
  className?: string;
  color?: BoxColor.Peach | BoxColor.Sky | BoxColor.Lavender;
  fullWidth?: boolean;
}

export enum BoxColor {
  Peach = "bg-peachBright",
  Sky = "bg-skyBright",
  Lavender = "bg-lavenderBright",
}

export const PointBox: FC<Props> = ({
  number,
  symbol,
  label,
  className,
  color = BoxColor.Peach,
  fullWidth,
}) => {
  const pointBoxContainer = classNames("flex-1", {
    "basis-full h-16": fullWidth,
    className,
  });

  const pointBoxStyle = classNames(
    color,
    "flex-1 min-h-[46px] min-w-[90px] border-1 border-black",
    "flex items-center justify-center"
  );

  return (
    <div className={pointBoxContainer}>
      <div className={pointBoxStyle}>
        <span>
          {String(number)}
          {symbol}
        </span>
      </div>
      <p className="text-[10px]">{label}</p>
    </div>
  );
};
