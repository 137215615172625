import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NewLeadInput } from "../employee/leads";
import { DepartmentType } from "../employee/leads/registerNewLead/RegisterNewLead";
import { updateDepartment } from "../../api/department";
import { updateOffice } from "../../api/office";
import { Button } from "../common";
import { components as APIcomponents } from "../../api/schema";
import { updateTeam } from "../../api/teams";

type OfficeType = APIcomponents["schemas"]["Office"];
type TeamType = APIcomponents["schemas"]["Teams"];

type Inputs = {
  name?: string;
  short?: string;
};
interface Props {
  item: DepartmentType | OfficeType | TeamType;
  type: "office" | "department" | "team";
}
export const EditForm: FunctionComponent<Props> = ({ type, item }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const [formComplete, setFormComplete] = useState(false);
  const [useShort, setUseShort] = useState(false);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setFormComplete(false);
      }, 1500);
    };
  }, [formComplete]);

  useEffect(() => {
    setUseShort(type === "department" || type === "team");
    setValue("name", item.name);
    setValue("short", item["short"] ? item["short"] : "");
  }, [type]);

  const onSubmit = async (data) => {
    const formData = {
      name: data.name,
      short: data.short,
    };
    if (!item._id) {
      return false;
    }
    switch (type) {
      case "department":
        return await updateDepartment(item._id, formData);
      case "office":
        return await updateOffice(item._id, formData);
      case "team":
        return await updateTeam(item._id, formData);
      default:
        break;
    }
    return false;
  };
  return (
    <div className="mt-4">
      <form
        className="px-6 lg:px-0 w-full"
        autoComplete="off"
        id="edit-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {useShort ? (
          <NewLeadInput
            className=" border-b-black w-full block "
            errorMessage={errors.short?.message}
            inputId="short"
            inputPlaceholder="cas"
            inputType="text"
            title="Short:"
            register={() => register("short")}
          />
        ) : (
          <></>
        )}
        <NewLeadInput
          className=" border-b-black w-full block "
          errorMessage={errors.name?.message}
          inputId="name"
          inputPlaceholder="Name"
          inputType="text"
          title={
            type === "office"
              ? "Office name:"
              : type === "department"
              ? "Department name"
              : "Team name"
          }
          register={() => register("name")}
        />

        <div className="block py-4">
          <Button color="lobster" name={`Update ${type}`} type="submit" />
        </div>
        {formComplete ? `Updated ${type}` : ""}
      </form>
    </div>
  );
};
