import { ReactElement, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Menu } from "./components/header/";
import { MSALLayout } from "./components/msallayout/MSALLayout";
import { AuthProvider } from "./helpers/useTestRecruiter";
import { Router } from "./routes";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { IteraStructureProvider } from "./helpers/useIteraStructureContext";

function App(): ReactElement {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.init("ez7pkn/mad-leads");
      setupLogRocketReact(LogRocket);
    }
  }, []);

  return (
    <MSALLayout>
      <main className="font-sans">
        <BrowserRouter>
          <AuthProvider>
            <IteraStructureProvider>
              <Menu />
              <Router />
            </IteraStructureProvider>
          </AuthProvider>
        </BrowserRouter>
      </main>
    </MSALLayout>
  );
}

export default App;
