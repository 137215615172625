import { FC } from "react";

import {
  calculateLevel,
  getColor,
  getStarCount,
} from "../../helpers/reward/rewardHelpers";

const StarSVG: FC<{ starColor: string }> = ({ starColor }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
      fill={starColor}
    />
  </svg>
);

export const Stars: FC<{ employeeScore: number; className?: string }> = ({
  employeeScore,
  className = "",
}) => {
  const employeeLevel = calculateLevel(employeeScore);
  const starCount = getStarCount(employeeLevel);
  const color = getColor(employeeLevel);
  return (
    <div className={className}>
      {Array(starCount)
        .fill(null)
        .map((_, i) => (
          <StarSVG key={"starIndex" + i} starColor={color} />
        ))}
    </div>
  );
};
