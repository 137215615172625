import { Link } from "react-router-dom";

interface Btn {
  btnText: string;
  link: string;
}

interface Props {
  text: string;
  btn: Btn;
}

export const RegisterTeamNotification = ({
  text,
  btn: { btnText, link },
}: Props) => {
  return (
    <div className="items-center py-8 px-6 bg-lavenderBright relative flex flex-col leading-10">
      <p>{text}</p>
      <Link
        to={link}
        className="justify-center flex flex-row py-[18px] px-[24px] bg-fuchsia text-white text-lg max-w-[217px]"
      >
        {btnText}
      </Link>
    </div>
  );
};
