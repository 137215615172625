import { FC } from "react";
import { getStatusColour } from "../Arrow";

export const Plus: FC<{
  buttonColor: string;
}> = ({ buttonColor }) => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.85205 11.25V20H11.3521V11.25H20V8.75H11.3521V0H8.85205V8.75H0V11.25H8.85205Z"
      fill={
        buttonColor === "gold" || buttonColor === "jade" ? "black" : "white"
      }
      className={getStatusColour(buttonColor)}
    />
  </svg>
);
