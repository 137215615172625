import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Path } from "../../../routes";
import { Podium } from "./Podium";

import classNames from "classnames";
import { ArrowTextButton } from "../../common/ArrowTextButton";
import { Reward } from "../../reward/Reward";
import { useLeaders } from "../../../helpers/useLeaders";
import { Spinner } from "../../common/Spinner";
import { getTimeIntervall } from "../../../helpers/sortLeads";

export const TopRecruiters: FC<{ className?: string; timeValue: string }> = ({
  className,
  timeValue,
}) => {
  const [queryOptions, setQueryOptions] = useState({});
  const { individuals: leaders, isLoading } = useLeaders(queryOptions);

  const [currentUser, currentPosition] = useMemo(() => {
    const isCurrent = ({ is_mine }) => is_mine;
    const currentUser = leaders.find(isCurrent) || null;
    if (!currentUser) return [null, null];
    const leadersSortedClone = leaders
      .slice()
      .sort((a, b) => b.points - a.points);
    const currentPosition = leadersSortedClone.findIndex(isCurrent) + 1;
    return [currentUser, currentPosition];
  }, [leaders]);

  function selectHandler(value) {
    setQueryOptions(getTimeIntervall(value));
  }

  useEffect(() => {
    selectHandler(timeValue);
  }, [timeValue]);

  return (
    <div className={classNames("flex flex-col pb-[70px]", className)}>
      <h4 className="text-left text-h4 mb-8">Top recruiters</h4>
      <div className="mb-10 flex-auto h-[300px]">
        {isLoading ? <Spinner /> : <Podium data={leaders} showReward />}
      </div>
      {currentUser && currentPosition > 3 && (
        <>
          <p className=" mb-[17px]">Your position</p>
          <div className="flex items-center h-14">
            <div className="text-left mr-3">{currentPosition}.</div>
            <div className="relative w-14 h-14 mr-3">
              <Reward
                name={currentUser.name}
                employeeScore={currentUser.points}
                teamScore={currentUser.team_points}
                avatarUrl={currentUser.avatar}
              />
            </div>
            <div className="flex items-center w-full h-14 p-3 mr-3 border border-black">
              {currentUser.name}
            </div>
            <div className="flex items-center h-14 p-3 pr-7 border border-black">
              {currentUser.points}
            </div>
          </div>
        </>
      )}
      <Link to={Path.LEADERBOARD}>
        <ArrowTextButton color="fuchsia" name="View full leaderboard" />
      </Link>
    </div>
  );
};
