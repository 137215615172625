import { FC, useState } from "react";
import classNames from "classnames";
import { components as SchemaComponent } from "../../../../api/schema";
import { ProgressBar } from "../../../common/ProgressBar";
import { StatusUpdatesType } from "../../../recruiter/leadLog";

export type LeadCardProps = SchemaComponent["schemas"]["Lead"] & {
  leadPoints: number;
};

export const LeadCard: FC<LeadCardProps> = (props) => {
  const {
    candidate,
    status,
    description,
    location,
    updated_at,
    status_updates,
    leadPoints,
  } = props;
  const [isDescriptionActive, setIsDescriptionActive] = useState(false);

  type Status = SchemaComponent["schemas"]["Status"];

  const previousStatuses = status_updates?.map(
    (el: StatusUpdatesType) => el.status
  ) as Status[];

  const progression = [
    {
      text: "Lead submitted",
      isActive: true,
    },
    {
      text: "Lead accepted",
      isActive: previousStatuses?.includes("accepted"),
    },
    {
      text: "In progress",
      isActive:
        ["1st_interview", "2nd_interview", "3rd_interview", "offer"].filter(
          (el) => previousStatuses?.includes(el as Status)
        ).length > 0,
    },
    {
      text: status === "closed" ? "Closed" : "Hired",
      isActive:
        ["hired", "closed"].filter((el) =>
          previousStatuses?.includes(el as Status)
        ).length > 0,
    },
  ];

  const statusText = {
    new: {
      title: "Lead submitted",
      message:
        "Your lead has been submitted to the system. A recruiter will look at your lead as soon as possible.",
    },
    accepted: {
      title: "Candidate accepted",
      message:
        "Your submitted lead has been accepted by a recruiter, and will be contacted as soon as possible.",
    },
    "1st_interview": {
      title: "In progress",
      message: "Lead process is in progress.",
    },
    "2nd_interview": {
      title: "In progress",
      message: "Lead process is in progress.",
    },
    "3rd_interview": {
      title: "In progress",
      message: "Lead process is in progress.",
    },
    offer: {
      title: "In progress",
      message: "Lead process is in progress.",
    },
    hired: {
      title: "Hired",
      message: "Your submitted lead has accepted the offer! Great lead!",
    },
    closed: {
      title: "Process Ended",
      message: "The recruitment process for this lead is ended.",
    },
  };
  const date = updated_at ? updated_at : "";

  return (
    <div className="flex flex-row w-full relative">
      <div className="flex-[0_0_70%] flex flex-row flex-wrap">
        <div className="flex-[0_0_7%] text-h4"></div>
        <div className="flex-[0_0_73%] flex flex-row flex-wrap">
          <div className="flex-[0_0_70%] flex flex-col justify-center">
            <h4 className="text-h4 underline">
              {candidate?.name ?? "Full name"}
            </h4>
          </div>
          <div className="flex-[0_0_30%] flex flex-col justify-center">
            <p className="text-overline text-right">
              Your points for this lead
            </p>
            <p className="text-h4 font-bold text-right">{leadPoints}</p>
          </div>
          <div className="flex-[0_0_100%] mt-16">
            <ProgressBar
              steps={progression}
              processEnded={status === "closed"}
            />
          </div>
        </div>
        <div className="flex-[0_0_20%] flex justify-center items-end border-r-2 border-fuchsia">
          <button
            className="mb-4"
            onClick={() => setIsDescriptionActive((old) => !old)}
          >
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classNames(
                isDescriptionActive ? "rotate-180" : "",
                "transition-all"
              )}
            >
              <path
                d="M15.885 0L9 6.79892L2.115 0L0 2.09312L9 11L18 2.09312L15.885 0Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div
          className={classNames(
            isDescriptionActive ? "" : "hidden",
            "flex-[0_0_100%] flex flex-row pt-8 gap-6 transition-all"
          )}
        >
          <div className="flex-[0_0_60%]">
            <p className="font-bold text-overline mb-4">Description</p>
            <p>{description ?? "Missing description"}</p>
          </div>
          <div className="flex-1">
            <ul>
              <li>
                LinkedIn:{" "}
                {candidate?.linkedin_url ? (
                  <a
                    target="_blank"
                    href={candidate?.linkedin_url}
                    rel="noreferrer"
                    className="underline"
                  >
                    Link
                  </a>
                ) : (
                  "Missing"
                )}
              </li>
              <li>E-mail: {candidate?.email ?? "Missing"}</li>
              <li>Phone: {candidate?.phone ?? "Missing"}</li>
              <li>Office: {location?.name ?? "Missing"}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex-[0_0_30%] flex flex-col pl-11">
        {status === "hired" && (
          <img
            src="/./medal.png"
            className="w-20 h-26 absolute bottom-0 right-0"
          />
        )}
        <h4 className={`mb-2 ${status === "hired" ? "font-bold" : ""}`}>
          {statusText[status]?.title}
        </h4>
        <p className="text-overline">{statusText[status]?.message}</p>
        <p className="text-overline mt-auto">
          {new Date(date).toLocaleString("no-NO", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </p>
      </div>
    </div>
  );
};
