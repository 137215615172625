import { FC, useMemo, useEffect, useRef, useState } from "react";
import { IndividualPoints } from "../../../helpers/useLeaders";
import { Reward } from "../../reward/Reward";

const PLACE_NUMBER_FONT_SIZE = 56;
const PLACE_NUMBER_FONT_SIZE_SMALL = 36;
const TOP_LIST_LIMIT = 3;

export interface TeamPoints {
  name: string | undefined;
  points: number;
  is_mine: boolean;
  team?:
    | "dev-1"
    | "dev-2"
    | "dev-3"
    | "dev-4"
    | "dev-5"
    | "dev-6"
    | "dev-7"
    | "arch-1"
    | "qa-1"
    | "fredrikstad"
    | "bergen"
    | undefined;
  avatar?: string | undefined;
  team_points?: number;
}

export const Podium: FC<{
  data: IndividualPoints[] | TeamPoints[];
  showReward?: boolean;
}> = ({ data, showReward }) => {
  if (!data.length) return null;
  const podiumRefs: any[] = [useRef(null), useRef(null), useRef(null)];
  const [heights, setHeights] = useState([0, 0, 0]);
  const [lastColumnHasLowHeight, setLastColumnHasLowHeight] = useState(false);
  const [numbersShouldBeSmall, setNumbersShouldBeSmall] = useState(false);
  const topList = useMemo(
    () => data.sort((a, b) => b.points - a.points).slice(0, TOP_LIST_LIMIT),
    [data]
  );

  const getClientHeight = ({ current }) => (current ? current.clientHeight : 0);

  const getHeigth = ({ points }, index, maxHeight) => {
    const minHeight = 48;
    if (!points) return minHeight;
    if (index === 0) return maxHeight;
    const height = (maxHeight * points) / topList[0].points;
    return height > minHeight ? height : minHeight;
  };

  const recalcColumns = () => {
    if (!podiumRefs[0].current) return;
    const maxColumnTopHeight = Math.max(...podiumRefs.map(getClientHeight));
    const {
      parentElement: { clientHeight: column1WithTopHeight },
    } = podiumRefs[0].current;
    const maxHeight = column1WithTopHeight - maxColumnTopHeight;
    const newHeights = topList.map(({ points }, index) =>
      getHeigth({ points }, index, maxHeight)
    );
    setHeights(newHeights);
    const lastColumnHeight = newHeights[2];
    setNumbersShouldBeSmall(lastColumnHeight < PLACE_NUMBER_FONT_SIZE);
    setLastColumnHasLowHeight(lastColumnHeight < PLACE_NUMBER_FONT_SIZE_SMALL);
  };

  useEffect(() => {
    window.addEventListener("resize", recalcColumns);
  }, []);

  useEffect(() => {
    setTimeout(recalcColumns, 100);
  }, [topList]);

  return (
    <div className="flex flex-row justify-between items-stretch relative h-full">
      {topList.map((leader, index) => (
        <div
          key={leader.name}
          className={`w-1/4 flex flex-col justify-end
          ${index === 1 ? "order-first" : ""}`}
        >
          <div ref={podiumRefs[index]}>
            {showReward && (
              <div className="flex justify-center mb-5">
                <Reward
                  name={leader.name}
                  employeeScore={leader.points}
                  teamScore={leader.team_points ? leader.team_points : 0}
                  avatarUrl={leader.avatar}
                />
              </div>
            )}
            <div className="border border-solid border-black text-center mb-3 p-1">
              <p className=" overflow-hidden">{leader.name}</p>
              <p className="text-xs">{leader.points + " p"}</p>
            </div>
          </div>
          <div
            className="flex items-center justify-center 
              border border-solid border-black bg-lavenderBright"
            style={{
              height: heights[index],
              transition: "height 500ms",
            }}
          >
            <span
              className="font-light absolute"
              style={{
                fontSize: numbersShouldBeSmall
                  ? PLACE_NUMBER_FONT_SIZE_SMALL
                  : PLACE_NUMBER_FONT_SIZE,
                bottom: lastColumnHasLowHeight
                  ? `-${1.25 * PLACE_NUMBER_FONT_SIZE_SMALL}px`
                  : "unset",
              }}
            >
              {index + 1}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
