import { FC, useEffect, useState } from "react";
import { IndividualLeaderboard } from "../../components/employee/leaderboards/IndividualLeaderboard";
import { TeamLeaderboard } from "../../components/employee/leaderboards/TeamLeaderboard";
import { TwoColumnLayout } from "../../components/layout/TwoColumnLayout";
import { useLeaders } from "../../helpers/useLeaders";
import classNames from "classnames";
import { Spinner } from "../../components/common/Spinner";
import { DropdownMenu } from "../../components/employee/dashboard/stats/DropdownMenu";
import { getTimeIntervall } from "../../helpers/sortLeads";
import { getTimeOptions } from "./helpers";

export const Leaderboard: FC = () => {
  const [queryOptions, setQueryOptions] = useState({});
  const { individuals, teams, isLoading } = useLeaders(queryOptions);
  const [selectKey, setSelectKey] = useState("Current period");

  const timeOptions = getTimeOptions;

  function selectHandler(value) {
    setQueryOptions(getTimeIntervall(value));
  }

  const handleTimeChange = (event) => {
    setSelectKey(event.target.value);
  };

  useEffect(() => {
    selectHandler(selectKey);
  }, [selectKey]);

  return (
    <>
      <div className="mx-auto max-w-[1200px] w-full py-11 px-6 [1200px]:px-0">
        <h2 className="text-fuchsia text-h2">Leaderboard</h2>
        <DropdownMenu
          label="Choose time period"
          options={timeOptions}
          value={selectKey}
          onChange={handleTimeChange}
          className={classNames()}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <TwoColumnLayout
          hasDivider
          hasMargin={false}
          headlines={{ left: "Individual", right: "Team" }}
          left={<IndividualLeaderboard data={individuals} />}
          right={<TeamLeaderboard data={teams} />}
        />
      )}
    </>
  );
};
