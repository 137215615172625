import { emojisplosion } from "emojisplosion";
import { Dispatch, FC, SetStateAction, useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NewLeadInput } from ".";
import classNames from "classnames";
import { NewLeadSelect } from "./Inputs";
import { Button } from "../../../common";
import { components as SchemaComponent } from "../../../../api/schema";

import useAuth from "../../../../helpers/useTestRecruiter";
import { useApi } from "../../../../helpers/useApi";
import { InfoBox } from "../../dashboard/header/InfoBox";
import { iteraStructureContext } from "../../../../helpers/useIteraStructureContext";
import { createLead } from "../../../../api/lead";
import { UserType } from "../../dashboard/stats/PointsOverview";

type RelationType = SchemaComponent["schemas"]["Relation"];
export type LocationType = SchemaComponent["schemas"]["Location"];
export type DepartmentType = SchemaComponent["schemas"]["Department"];
export type PCSType = SchemaComponent["schemas"]["PCS"];
type LeadDataType = SchemaComponent["schemas"]["CreateLead"];

type Inputs = {
  name: string;
  linkedIn: string;
  email: string;
  mobile: number;
  location: LocationType;
  department: DepartmentType;
  level: PCSType;
  relation: RelationType;
  description: string;
  on_behalf_of: string;
};

interface RegisterNewLeadProps {
  formCompleteCallback: Dispatch<SetStateAction<boolean>>;
}

export const RegisterNewLead: FC<RegisterNewLeadProps> = ({
  formCompleteCallback,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();
  const [, ...toggleModeAndAccount] = useAuth();
  const account = toggleModeAndAccount[1];
  const { data, error } = useApi<UserType>(
    `/users/${account?.homeAccountId?.split(".")[0]}`
  ) || { data: {}, error: {} };

  const isAdmin = !error && data?.roles?.includes("admin");

  const { offices, departments } = useContext(iteraStructureContext);

  const [isContactInfoProvided, setIsContactInfoProvided] = useState(false);

  const createEsplosion = () => {
    let count = 25;
    const repeater = setInterval(() => {
      if (count === 0) {
        clearInterval(repeater);
      } else {
        emojisplosion({
          emojiCount: 4,
          emojis: ["🎉", "🥳", "🙌", "🎊"],
        });
        count--;
      }
    }, 300);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const leadData: LeadDataType = {
      candidate: {
        name: data.name,
        email: data.email,
        phone: String(data.mobile),
        linkedin_url: data.linkedIn,
      },
      description: data.description,
      location: data.location,
      department: data.department,
      on_behalf_of: data.on_behalf_of,
    };
    createLead(leadData)
      .then((res) => {
        if (res.error) {
          alert(res.message);
          return;
        }
        formCompleteCallback(true);
        createEsplosion();
      })
      .catch((err) => console.error(err));
    return false;
  };

  const updateContactInfo = () => {
    const values = getValues();
    if (
      values.linkedIn.length > 0 ||
      values.email.length > 0 ||
      values.mobile > 0
    ) {
      setIsContactInfoProvided(true);
    } else {
      setIsContactInfoProvided(false);
    }
  };

  return (
    <form
      className="px-6 lg:px-0 w-full"
      autoComplete="off"
      id="new-lead"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 className="text-h3 mb-6">Candidate information</h3>
      <div className="flex flex-row flex-wrap w-full gap-0 md:gap-8">
        <div className="flex flex-col flex-[0_0_100%] lg:flex-1">
          <NewLeadInput
            errorMessage={errors.name?.message}
            inputId="name"
            inputPlaceholder="Ola Nordmann"
            inputType="text"
            title="Name"
            register={() =>
              register("name", {
                required: "Complete name is required",
                pattern: {
                  value: /^[a-zA-Z-æøåÆØÅ].*/,
                  message: "Name must start with a letter of the alphabet",
                },
              })
            }
          />
          <NewLeadInput
            errorMessage={errors.linkedIn?.message}
            inputId="linkedin"
            inputPlaceholder="no.linkedin.com/in/name-nameson"
            inputType="text"
            title="LinkedIn URL"
            register={() =>
              register("linkedIn", {
                pattern: {
                  value: /linkedin.com/,
                  message: "URL is not a valid LinkedIn url",
                },
                required:
                  !isContactInfoProvided &&
                  "Either e-mail, LinkedIn URL or phone number must be provided",
                onBlur: updateContactInfo,
              })
            }
          />
          <NewLeadInput
            errorMessage={errors.email?.message}
            inputId="email"
            inputPlaceholder="name@domain.com"
            inputType="email"
            title="E-mail"
            register={() =>
              register("email", {
                required: !isContactInfoProvided,
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                  message: "The input is not a valid e-mail",
                },
                onBlur: updateContactInfo,
              })
            }
          />
          <NewLeadInput
            errorMessage={errors.mobile?.message}
            inputId="mobile"
            inputType="tel"
            inputPlaceholder="+4700000000"
            title="Mobile"
            register={() =>
              register("mobile", {
                required: !isContactInfoProvided,
                pattern: {
                  value: /^((00)|(\+))\d{10,15}$/,
                  message: "The input is not a valid phone number",
                },
                onBlur: updateContactInfo,
              })
            }
          />

          <NewLeadSelect
            errorMessage={errors.location?.message}
            title="Office"
            inputId="location"
            register={() =>
              register("location", {
                required: "Location is required",
              })
            }
            options={offices}
          />
        </div>
        <div className="flex flex-col flex-[0_0_100%] lg:flex-1">
          {isAdmin && (
            <NewLeadInput
              errorMessage={errors.on_behalf_of?.message}
              inputId="on_behalf_of"
              inputPlaceholder="Kari Nordmann"
              inputType="text"
              title="Lead on behalf of"
              register={() =>
                register("on_behalf_of", {
                  required: false,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Complete name is required",
                  },
                })
              }
            />
          )}
          <NewLeadSelect
            errorMessage={errors.department?.name?.message}
            title="Which department might be relevant for the candidate?"
            inputId="department"
            register={() =>
              register("department", {
                required: "Department is required",
              })
            }
            options={departments}
          />

          <label htmlFor="description">
            <h4>Description</h4>
            <p className="text-caption mb-2">
              Give a brief description of how you know this person, and why you
              would like to recommend them to Itera.
            </p>
          </label>
          <textarea
            id="description"
            title="Please describe how you know this person, and why you would like to recommend them to Itera"
            maxLength={2000}
            className={classNames(
              " w-full border-white placeholder:italic placeholder:text-gray-300",
              errors.description?.message
                ? "border-lobster"
                : "border-transparent"
            )}
            {...register("description", {
              required: "Description is required",
              pattern: {
                value: /^(?!\s*$).+/,
                message: "Description is required",
              },
              onBlur: updateContactInfo,
            })}
          />
          <span
            role="alert"
            id="description-alert"
            className="mb-6 mt-1 text-lobsterNight"
          >
            {errors.description?.message}
          </span>
          <InfoBox
            text="When a person is hired, you get a bonus of"
            bonus={25000}
            helpText="*Read the rules for this here"
            helpUrl="https://iteracloud.sharepoint.com/sites/framework/Itera%20Framework/Referral%20Program.aspx"
          />
        </div>
        <div className="flex-[0_0_100%]">
          <Button
            id="submit"
            type="submit"
            name="Submit new lead"
            color="fuchsia"
          />
        </div>
      </div>
    </form>
  );
};
