import { FC, ReactNode } from "react";

import classNames from "classnames";

interface Headlines {
  left?: string;
  right?: string;
}

interface TwoColumnLayoutProps {
  header?: string;
  subHeader?: string;
  headlines?: Headlines;
  gap?: number;
  left?: ReactNode;
  right?: ReactNode;
  hasMargin?: boolean;
  hasDivider?: boolean;
  threeFifth?: boolean;
}

export const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({
  header,
  subHeader,
  headlines,
  left,
  right,
  hasMargin = true,
  hasDivider,
  threeFifth,
}) => {
  const commonColumnClassName = classNames("mx-auto w-full px-6", {
    "max-w-[509px]": !threeFifth,
  });
  return (
    <div className={classNames({ "px-9 my-24": hasMargin })}>
      <h4 className="text-fuchsia text-h4 mb-4 ml-6">{header}</h4>
      <h4 className="mb-6 ml-8">{subHeader}</h4>
      <div
        className={classNames(
          "block lg:flex lg:flex-row max-w-[1200px] mx-auto"
        )}
      >
        <div
          className={classNames(commonColumnClassName, "lg:mr-auto lg:ml-0", {
            "lg:w-3/5": threeFifth,
          })}
        >
          {headlines?.left ? (
            <h4 className="text-fuchsia text-h4 mb-6">{headlines?.left}</h4>
          ) : null}
          {left}
        </div>
        {hasDivider && (
          <div className="border-solid border-lavenderBright border-l"></div>
        )}
        <div
          className={classNames(
            commonColumnClassName,
            "lg:ml-auto lg:mr-0 mt-10 lg:mt-0",
            { "lg:w-2/5": threeFifth }
          )}
        >
          {headlines?.right ? (
            <h4 className="text-fuchsia text-h4 mb-6">{headlines?.right}</h4>
          ) : null}
          {right}
        </div>
      </div>
    </div>
  );
};
