import { FC, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Path } from "../../../routes";
import { Podium, TeamPoints } from "./Podium";

import { Spinner } from "../../common/Spinner";
import { ArrowTextButton } from "../../common/ArrowTextButton";
import { useLeaders } from "../../../helpers/useLeaders";
import { getTimeIntervall } from "../../../helpers/sortLeads";
import { iteraStructureContext } from "../../../helpers/useIteraStructureContext";

export const TeamsPodium: FC<{ className?: string; timeValue: string }> = ({
  className,
  timeValue,
}) => {
  const [queryOptions, setQueryOptions] = useState({});
  const { teams, isLoading } = useLeaders(queryOptions);

  const { getTeams } = useContext(iteraStructureContext);

  function selectHandler(value) {
    setQueryOptions(getTimeIntervall(value));
  }

  useEffect(() => {
    selectHandler(timeValue);
  }, [timeValue]);

  const teamDataWithFullNames = teams
    ? teams.map((team) => ({ ...team, name: getTeams(team.name)?.name }))
    : [];

  return (
    <div className={classNames("flex flex-col", className)}>
      <h4 className="text-left text-black text-h4 font-normal mb-12">
        Top teams
      </h4>
      <div className="mb-12 flex-auto h-[200px]">
        {isLoading ? (
          <Spinner />
        ) : (
          <Podium data={teamDataWithFullNames as TeamPoints[]} />
        )}
      </div>
      <Link to={Path.LEADERBOARD}>
        <ArrowTextButton color="fuchsia" name="View full leaderboard" />
      </Link>
    </div>
  );
};
