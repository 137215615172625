import { FC } from "react";
import { BoxColor, PointBox } from "./PointBox";

interface PointsOverviewComponentProps {
  data: any;
  header: string;
  points?: number;
}

export const PointsOverviewComponent: FC<PointsOverviewComponentProps> = ({
  data,
  header,
  points,
}) => {
  const isWhatPercentOf = (numA: number, numB: number) => {
    return (numA / numB) * 100;
  };

  return (
    <div className="mt-5 flex flex-wrap gap-4 w-2/4">
      <div className="flex">
        <h1>{header}</h1>
      </div>
      {data && (
        <>
          <PointBox number={points} color={BoxColor.Lavender} fullWidth />
          <PointBox number={data.submitted} label="Submitted" />
          <PointBox number={data.accepted} label="Accepted" />
          <PointBox number={data.hired} label="Employments" />
          <PointBox
            number={
              data.submitted > 0
                ? Math.floor(isWhatPercentOf(data.hired, data.submitted))
                : 0
            }
            label="Success rate"
            color={BoxColor.Sky}
            symbol="%"
          />
        </>
      )}
    </div>
  );
};
