import { useState } from "react";
import { Switch } from "../../../components/common/Switch";
import { FC } from "react";
import { LeadType } from "../../../helpers/sortLeads";

export const ToggleClosedLeads: FC<{
  initialText?: string;
  initialState: boolean;
  setLeads: (val: LeadType[]) => void;
  data: LeadType[];
}> = ({ initialText, initialState, setLeads, data }) => {
  const [label, setLabel] = useState(initialText);

  const FilterClosedLeads = (toggle: boolean) => {
    const tempData = data ? data : [];

    const tempLeads = tempData.filter(
      (lead: { status: string }) => lead.status !== "closed"
    );

    if (toggle) {
      setLabel("Show closed leads");
      setLeads(tempLeads);
    } else {
      setLabel("Hide closed leads");
      setLeads(tempData);
    }
  };

  return (
    <div className="flex flex-wrap flex-col items-start md:flex-row md:items-end mb-4">
      <p className="pr-2">{label}</p>
      <Switch
        initialState={initialState}
        switchToggleHandler={FilterClosedLeads}
      />
    </div>
  );
};
