import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { API_TOKEN_STATE, API_USERID } from "./auth/authConfig";
import { add } from "date-fns";
import LogRocket from "logrocket";
import { createUser, getUser } from "../api/user";

type RecruiterContext = [boolean, () => void, AccountInfo | null];

export const AuthContext = createContext<RecruiterContext>([
  false,
  () => null,
  null,
]);

interface AuthProviderProps {
  children: React.ReactNode;
}
export interface LocalStorageToken {
  token: string;
  exp: number;
}

export const getToken = (): string | null => {
  const local = localStorage.getItem(API_TOKEN_STATE);
  if (local) {
    const localStorageObject: LocalStorageToken = JSON.parse(local);
    if (localStorageObject.exp < new Date().getTime()) {
      // Token has expired
      localStorage.removeItem(API_TOKEN_STATE);
      (location as any).reload();
      return null;
    }
    return localStorageObject.token;
  } else {
    return null;
  }
};
export const setToken = (token: string) => {
  const timeOfDeath = add(new Date(), { minutes: 30 });
  const localStorageObject: LocalStorageToken = {
    token: token,
    exp: timeOfDeath.getTime(),
  };
  localStorage.setItem(API_TOKEN_STATE, JSON.stringify(localStorageObject));
};

export const setUserId = (userId: string) => {
  localStorage.setItem(API_USERID, userId);
};

export const getUserId = () => {
  return localStorage.getItem(API_USERID);
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { accounts, instance } = useMsal();
  const { children } = props;
  const [isRecruiter, setIsRecruiter] = useState<boolean>(() => {
    const valueInLocalStorage = window.localStorage.getItem("is-recruiter");
    if (valueInLocalStorage) {
      return valueInLocalStorage === "true";
    }
    return false;
  });

  useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
      if (
        message.eventType === EventType.LOGIN_SUCCESS ||
        message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        const result = message.payload as AuthenticationResult;
        if (process.env.NODE_ENV === "production") {
          LogRocket.identify(result.uniqueId, {
            name: result.account?.name || "",
            email: result.account?.username || "",
          });
        }
        setToken(result.idToken);
        setUserId(result.uniqueId);
        getUser(result.uniqueId).then((data) => {
          if (data.error) {
            createUser(result.uniqueId).then((data) => {
              setUserId(data.external_id);
            });
          }
        });
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    const request = {
      account: accounts[0],
      scopes: [`api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/read`],
    };
    instance
      .acquireTokenSilent(request)
      .then((result) => {
        setToken(result.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(request)
            .then((result) => {
              setToken(result.accessToken);
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
            });
        }
        console.log(error);
      });
  }, []);

  const toggleMode = () => {
    setIsRecruiter((oldValue) => {
      window.localStorage.setItem("is-recruiter", String(!oldValue));
      return !oldValue;
    });
  };

  return (
    <AuthContext.Provider value={[isRecruiter, toggleMode, accounts[0]]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
