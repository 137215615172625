import classNames from "classnames";
import { getDisableColor } from "../ArrowTextButton";
import { getButtonColor } from "../Button";
import { Plus } from "../Plus/Plus";
import { FC } from "react";

export const PlusTextButton: FC<{
  className?: string;
  disabled?: boolean;
  name?: string;
  color: string;
}> = ({ className = "", name, color, ...otherProps }) => (
  <button
    {...otherProps}
    className={classNames(
      " group flex flex-row font-sans font-semibold px-[24px] py-[18px] gap-2 position-absolute border-1 cursor-pointer",
      " hover:bg-white ease-out duration-200",
      color === "gold" || color === "jade"
        ? "text-black hover:text-black focus:text-black"
        : "text-white",
      className + getButtonColor(color)
    )}
  >
    <Plus buttonColor={color} />
    <div
      className={
        "text-" + color + "group-disabled:text-" + getDisableColor(color)
      }
    >
      {name}
    </div>
  </button>
);
