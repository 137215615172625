import { ChangeEvent, FC, useEffect, useState } from "react";
import { DashboardHeader } from "../../components/employee/dashboard/header/DashboardHeader";
import { DashboardStats } from "../../components/employee/dashboard/stats/DashboardStats";
import {
  PointsOverview,
  UserType,
} from "../../components/employee/dashboard/stats/PointsOverview";
import { TeamsPodium } from "../../components/employee/Podium/TeamsPodium";
import { TopRecruiters } from "../../components/employee/Podium/TopRecruiters";
import { TwoColumnLayout } from "../../components/layout/TwoColumnLayout";
import { Path } from "../../routes";
import { RegisterTeamNotification } from "../../components/employee/dashboard/header/RegisterTeamNotification";
import { useApi } from "../../helpers/useApi";
import { useMsal } from "@azure/msal-react";
import { DropdownMenu } from "../../components/employee/dashboard/stats/DropdownMenu";
import { getTimeOptions } from "./helpers";

const dashboardBoxClassNames = "xsm:border-2 rounded-3xl xsm:p-6 ";

export const Dashboard: FC = () => {
  const { accounts } = useMsal();
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState<UserType>();
  const [teamIsMissing, setTeamIsMissing] = useState(false);
  const { data: userData } = useApi<UserType>(`/users/${userId}`);
  const [timeValue, setTimeValue] = useState("Current period");

  const handleTimeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    setTimeValue(target.value);
  };

  useEffect(() => {
    if (accounts && accounts[0] && accounts[0].homeAccountId?.split(".")[0]) {
      setUserId(accounts[0].homeAccountId.split(".")[0]);
    }
    if (userId) setUser(userData);

    type ObjectKey = keyof typeof user;
    const userTeam = "team" as ObjectKey;
    if (user) {
      if (user[userTeam]) {
        setTeamIsMissing(false);
      } else {
        setTeamIsMissing(true);
      }
    }
  }, [teamIsMissing, user, accounts, userId, userData, timeValue]);

  return (
    <div className="bg-white w-full">
      {teamIsMissing && (
        <RegisterTeamNotification
          text="We noticed you have not registered a team."
          btn={{
            btnText: "Register your team",
            link: Path.USERPROFILE,
          }}
        />
      )}
      <div className="mx-auto max-w-6xl w-full py-10">
        <DashboardHeader
          title="Welcome to MAD Leads!"
          subtitle="Submit a lead about a potential new employee."
          btn={{ text: "Submit new lead", link: Path.NEWLEAD }}
        />
        <DashboardStats title="How many leads actually lead to people getting hired?" />
        <div className="ml-8 px-6 mb-8">
          <h4 className="text-fuchsia text-h4 mt-8 pt-11">
            Points and leaderboards
          </h4>
          <h4>"What's this? Why do I have points? What do I use them for?"</h4>
          <DropdownMenu
            label="Choose time period"
            options={getTimeOptions}
            value={timeValue}
            onChange={handleTimeChange}
          />
        </div>
        <TwoColumnLayout
          hasMargin={false}
          threeFifth
          left={
            <>
              <PointsOverview
                timeValue={timeValue}
                className={dashboardBoxClassNames + " mb-10 ml-8 px-6"}
              />
              <TopRecruiters
                timeValue={timeValue}
                className={dashboardBoxClassNames + "ml-8 px-6"}
              />
            </>
          }
          right={
            <TeamsPodium
              timeValue={timeValue}
              className={dashboardBoxClassNames + " lg:h-full"}
            />
          }
        />
      </div>
    </div>
  );
};
