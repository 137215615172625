import classNames from "classnames";
import { ReactComponent as Union } from "../../../images/icons/union.svg";

export const CloseButton = ({
  className = "",
  backgroundColor = "bg-lobster",
  ...otherProps
}) => (
  <button
    {...otherProps}
    className={classNames(
      "flex place-content-center  w-[64px] h-[64px] cursor-pointer",
      className + backgroundColor
    )}
  >
    <Union className="place-self-center" />
  </button>
);
