import { FC } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";

import { Button } from "../../../components/common";
import { NoteType } from "./helpers";
import { CloseButton } from "../../../components/common/CloseButton";

const TextArea = (props) => {
  const { id, title, errorMessage, register, ...otherProps } = props;
  return (
    <>
      {title && <label htmlFor={id}>{title}</label>}
      <textarea
        aria-invalid={errorMessage ? "true" : false}
        className={classNames(
          " w-full border-white placeholder:italic placeholder:text-gray-300",
          errorMessage ? "border-lobster" : "border-transparent"
        )}
        {...{ ...otherProps, ...register() }}
      />
      <span role="alert" id={`${id}-alert`} className="mb-6 mt-1 text-lobster">
        {errorMessage}
      </span>
    </>
  );
};

export const NoteEditor: FC<{
  note?: NoteType;
  onSave: (note: { title?: string; text?: string }) => void;
  onClose: () => void;
  isNew?: boolean;
}> = ({ note, onSave, onClose, isNew = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    title: string;
    text: string;
  }>();

  const onSubmit = (formData) => {
    if (isNew) onSave(formData);
    onClose();
  };

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 cursor-pointer">
        <div className="relative my-6 mx-auto w-[90%] max-w-3xl">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="border-0 p-6 pt-16 gap-4 rounded-lg shadow-lg relative flex flex-col w-full bg-lavenderBright"
          >
            <TextArea
              id="noteTitle"
              placeholder="Title of note"
              rows={2}
              maxLength={200}
              defaultValue={note?.title || ""}
              register={() =>
                register("title", {
                  required: "Note title is required",
                  minLength: {
                    value: 5,
                    message: "Note title should be at least 5 characters long.",
                  },
                })
              }
              errorMessage={errors.title?.message}
            />
            <TextArea
              id="noteMessage"
              placeholder="Note text"
              rows={10}
              maxLength={2000}
              defaultValue={note?.text || ""}
              register={() =>
                register("text", {
                  required: "Note text is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "The note cannot be empty.",
                  },
                })
              }
              errorMessage={errors.text?.message}
            />
            <div className="flex flex-col xsm:flex-row justify-between gap-4">
              <Button name="Cancel" onClick={onClose} color="fuchsia" />
              <Button
                id="submit"
                name={isNew ? "Create note" : "Update note"}
                color="fuchsia"
                type="submit"
              />
            </div>
            <CloseButton
              className="absolute top-1 right-2 ml-auto"
              onClick={onClose}
              backgroundColor="bg-lavenderBright"
            />
          </form>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
