interface Props {
  text: string;
  bonus: number;
  helpText: string;
  helpUrl: string;
}
export const InfoBox = ({ text, bonus, helpText, helpUrl }: Props) => {
  return (
    <div className="py-8 px-6 bg-lavenderBright relative flex flex-col leading-10">
      <p>{text}</p>
      <h3 className="font-bold text-h3">NOK {bonus}!*</h3>
      <a
        className="text-bodyLink underline text-right ml-auto mt-4"
        target="_blank"
        href={helpUrl}
      >
        {helpText}
      </a>
    </div>
  );
};
