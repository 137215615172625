import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../helpers/auth/authConfig";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { ReactComponent as MadLeadsLogo } from "../../images/logos/mad-leads-logo.svg";

import { FC } from "react";
import { ArrowTextButton } from "../common/ArrowTextButton";

export const MSALLayout: FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const logIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => console.log(e));
  };

  if (!isAuthenticated) {
    return (
      <UnauthenticatedTemplate>
        <div className="bg-fuchsia flex pt-[10%] min-h-screen justify-center">
          <div className="flex flex-col gap-8 mobile:gap-2">
            <MadLeadsLogo className="mobile:w-2/3 mobile:self-center" />
            <div className=" mobile:self-center">
              <ArrowTextButton color="white" name="Log in" onClick={logIn} />
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    );
    //
  }
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};
