import classNames from "classnames";
import { Button } from "../Button";

export enum MessageType {
  INFO = "Information",
  WARNING = "Warning",
  ERROR = "Error",
  SUCCESS = "Success",
}

export const Snackbar = ({ message, onClose, type = MessageType.INFO }) =>
  !message ? null : (
    <>
      <div
        className="overflow-hidden fixed inset-0 z-999 p-4 
            flex justify-center items-center cursor-pointer"
        onClick={onClose}
      >
        <div
          className={classNames("p-5 max-w-3xl relative", {
            "bg-white": type === MessageType.INFO,
            "bg-peachBright text-lobster": type === MessageType.ERROR,
          })}
        >
          <p className="font-semibold mb-2">{type}</p>
          <p className="italic">{message}</p>
          <Button
            name="x"
            className="hover:bg-gray-100 absolute top-0 right-2 text-black"
          />
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-998 bg-black"></div>
    </>
  );
