import { FC } from "react";
import { components as SchemaComponent } from "../../../api/schema";
import classNames from "classnames";
import { format } from "date-fns";

export type StatusUpdatesType = SchemaComponent["schemas"]["StatusUpdate"];

export type LeadLogProps = {
  statusUpdates?: StatusUpdatesType[];
  className?: string;
};

export const LeadLog: FC<LeadLogProps> = ({ statusUpdates, className }) => {
  if (!statusUpdates) return null;
  return (
    <div className={classNames("flex flex-col w-full p-4", className)}>
      <h4 className="pb-2 ">Log</h4>
      <table className="table-fixed ">
        <thead className="bg-lavenderBright border-gray-200 border-1">
          <tr className="font-semibold">
            <td className={tableDataClass}>Action</td>
            <td className={tableDataClass}>Date</td>
          </tr>
        </thead>
        <tbody className="border-gray-200 border-1">
          {statusUpdates.map((statusUpdate, i) => {
            return (
              <tr key={i}>
                <td className={tableDataClass}>
                  {FormatStatus(statusUpdate.status)}
                </td>
                <td className={tableDataClass}>
                  <p>
                    {format(new Date(statusUpdate.timestamp), "MMM dd, yyyy")}
                  </p>
                  <p className="text-xs text-gray-400">
                    Submitted by {statusUpdate.updated_by?.name}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const tableDataClass = `border-gray-200 border-1 px-2 py-2 text-overline`;

function FormatStatus(status) {
  switch (status) {
    case "new":
      return "Lead Submitted";
    case "accepted":
      return "Lead Accepted";
    case "contacted":
      return "Lead Contacted";
    case "1st_interview":
      return "1st Interview";
    case "2nd_interview":
      return "2nd Interview";
    case "3rd_interview":
      return "3rd Interview";
    case "offer":
      return "Offer";
    case "hired":
      return "Hired!";
    case "closed":
      return "Closed";
    default:
      return "No status";
  }
}
