import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Branding } from ".";
import { useApi } from "../../helpers/useApi";
import useAuth from "../../helpers/useTestRecruiter";
import { Path } from "../../routes";
import { UserType } from "../employee/dashboard/stats/PointsOverview";

export const TopBar: FC = () => {
  const navigation = useNavigate();
  const [isRecruiter, setIsRecruiter, accountData] = useAuth();
  const { instance } = useMsal();
  const { data, error } = useApi<UserType>(
    `/users/${accountData?.homeAccountId.split(".")[0]}`
  );

  const handleLogOut = (instance: IPublicClientApplication) => {
    instance.logoutPopup().catch((e: string) => console.error(e));
  };

  return (
    <div className="bg-black flex flex-row flex-wrap justify-between w-full px-6 py-4">
      <Branding />
      <div className="flex flex-row text-ivory gap-4 text-overline align-middle content-center">
        {isRecruiter ? (
          <p className="bg-grass text-black p-1">RecruiterMode</p>
        ) : (
          <p className="bg-fuchsia text-black p-1">EmployeeMode</p>
        )}
        {data && !error && data.roles?.includes("recruiter") && (
          <button
            onClick={() => {
              setIsRecruiter();
              navigation("/", { replace: true });
            }}
          >
            Toggle recruiter
          </button>
        )}
        <button onClick={() => handleLogOut(instance)}>Sign out </button>
        <span className="h-full block bg-ivory" style={{ width: "1px" }} />
        <button onClick={() => navigation(Path.USERPROFILE)}>
          {accountData?.name}
        </button>
        <img
          className="w-8 bg-ivory h-8 rounded-full"
          alt="Profile icon"
          src={`https://imageiterastorage.blob.core.windows.net/images-prod/${
            accountData?.username?.split("@")[0]
          }.jpg?sv=2020-02-10&ss=b&srt=sco&sp=rlx&se=2024-02-01T16:04:40Z&st=2021-05-17T07:04:40Z&spr=https&sig=Vc0655DnQG2c7JdAmniMP9HivWju50to6Zzq%2FN6df1s%3D`}
        />
      </div>
    </div>
  );
};
