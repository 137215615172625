import classNames from "classnames";
import { Arrow } from "../Arrow/Arrow";
import { getButtonColor } from "../Button";
import { FC } from "react";

export const getDisableColor = (color: string) => {
  let disableColor = "";
  switch (color) {
    case "fuchsia":
      disableColor = "lavendel";
      break;
    case "lobster":
      disableColor = "peach";
      break;
    case "sea":
      disableColor = "sky";
      break;
    case "jade":
      disableColor = "grass";
      break;
    case "gold":
      disableColor = "lemon";
      break;
  }
  return disableColor;
};

export const ArrowTextButton: FC<{
  onClick?: () => void;
  className?: string;
  name?: string;
  color: string;
  disabled?: boolean;
}> = ({ className = "", color, name, onClick, ...otherProps }) => (
  <button
    onClick={onClick}
    {...otherProps}
    className={classNames(
      "group flex flex-row font-sans px-[24px] py-[18px] gap-2 position-absolute border-1 font-semibold cursor-pointer",
      " hover:bg-white ease-out duration-200",
      color === "gold" || color === "jade"
        ? "text-black hover:text-black focus:text-black"
        : "text-white ",
      className + getButtonColor(color)
    )}
  >
    <Arrow buttonColor={color} />
    <div
      className={
        "text-" + color + "group-disabled:text-" + getDisableColor(color)
      }
    >
      {name}
    </div>
  </button>
);
