import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

const webClientId = process.env.REACT_APP_AZURE_AD_CLIENT_ID;
const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID;
const webUrl = process.env.REACT_APP_WEB_URL ?? "http://localhost:3000";

export const msalConfig: Configuration = {
  auth: {
    clientId: `${webClientId}`,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: webUrl,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const LOGIN_STATE = "login";

export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"],
  state: LOGIN_STATE,
};

export const API_TOKEN_STATE = "api-token";
export const API_USERID = "api-userid";

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
